import { createSelector, createFeatureSelector } from '@ngrx/store';
import * as fromUser from './users.reducer';
import * as fromContacts from './contacts.reducer';
import * as fromCampaigns from './campaigns.reducer';
import * as fromConversations from './conversation.reducer';
import * as fromMessages from './messages.reducer';
import * as fromSettings from './settings.reducer';
import * as fromTags from './tags.reducer';
import * as fromUsersForCampaigns from './users-for-campaign.reducer';
import { ContactState, UserState } from '../../model';

export const user = fromUser.user;
export const userDictionary = fromUser.userDictionary;
export const userReducer = fromUser.userReducer;

export const contactsReducer = fromContacts.contactsReducer;
export const contactDictionary = fromContacts.contactDictionary;

export const conversationReducer = fromConversations.conversationReducer;
// export const conversationsReducer = fromConversations.conversationsReducer;
export const conversationsSummariesReducer = fromConversations.conversationsSummariesReducer;
export const userToCampaignConversationsReducer = fromConversations.userToCampaignConversationsReducer;
export const unreadReducer = fromConversations.unreadReducer;

export const campaignsReducer = fromCampaigns.campaignsReducer;
export const userToCampaignsReducer = fromCampaigns.userToCampaignsReducer;

export const messagesReducer = fromMessages.messagesReducer;

export const settingsReducer = fromSettings.settingsReducer;

export const tagsReducer = fromTags.tagsReducer;
export const tagDictionary = fromTags.tagDictionary;

export const usersForCampaignsReducer = fromUsersForCampaigns.usersForCampaignsReducer;

import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { filter } from 'rxjs/operators';

import { AuthenticationService } from '../../services';
import { strongPasswordValidator } from '../brochure/org-sign-up/org-sign-up.component';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.page.html',
  styleUrls: ['./change-password.page.scss']
})
export class ChangePasswordPage implements OnInit {
  passwordForm: UntypedFormGroup;
  showOriginalPassword: boolean;
  showPassword1: boolean;
  showPassword2: boolean;

  constructor(
    private auth: AuthenticationService,
    private fb: UntypedFormBuilder
  ) { }

  ngOnInit() {
    this.showOriginalPassword = false;
    this.showPassword1 = false;
    this.showPassword2 = false;

    this.passwordForm = this.fb.group({
      currentPassword: this.fb.control(''),
      password: this.fb.control(''),
      passwordConfirm: this.fb.control('')
    });

    return this.auth.getStatus().pipe(
      filter(v => v === 'ready')
    ).subscribe(v => {
      this.createForms();
    });
  }

  toggleShowOriginalPassword() {
    this.showOriginalPassword = !this.showOriginalPassword;
  }

  toggleShowPassword1() {
    this.showPassword1 = !this.showPassword1;
  }

  toggleShowPassword2() {
    this.showPassword2 = !this.showPassword2;
  }

  createForms() {
    const user = this.auth.getCurrentUser();

    const pwd:UntypedFormControl = this.fb.control('');
    const pwdConfirm:UntypedFormControl = this.fb.control('', [Validators.required]);
    pwd.setValidators([Validators.required, strongPasswordValidator(pwdConfirm)]);
    pwdConfirm.setValidators([Validators.required, strongPasswordValidator(pwd, true)]);

    this.passwordForm = this.fb.group({
      currentPassword: this.fb.control(''),
      password: pwd,
      passwordConfirm: pwdConfirm
    });
  }

  get password() { return this.passwordForm.get('password'); }

  get confirmPassword() { return this.passwordForm.get('passwordConfirm'); }

  savePassword() {
    if (this.passwordForm.value.password.length < 6) {
      alert('The new password must be at least 6 characters long');
      return;
    } else if (this.passwordForm.value.password === this.passwordForm.value.currentPassword) {
      alert('Your new password must be different than your current password.');
      return;
    }

    this.auth.changePassword(this.passwordForm.value.currentPassword, this.passwordForm.value.password)
      .then(() => {
        console.log('Success');
        this.createForms();
        alert('Your password has been changed');
      })
      .catch((err) => {
        console.log('Failure', err);
        switch (err.code) {
          case 'auth/weak-password':
            alert('Your new password is too simple. Please pick a more complex password and try again.');
            break;
          case 'auth/wrong-password':
            alert('It appears you mistyped your current password. Please try again.');
            break;
          default:
            alert('There was a problem changing your password. Please try again.');
        }
      });
  }
}

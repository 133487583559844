import { EntityState } from '@ngrx/entity';

export class Tag {
  id?: string;
  label: string;
  color?: string = '#4285f4';

  constructor() {}
}

export interface TagState extends EntityState<Tag> { }

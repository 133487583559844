import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from './environments/environment';
// @ts-ignore
import LogRocket from 'logrocket';

import { AppModule } from './app/app.module';

LogRocket.init('cpqy7x/parlance');
console.log('LogRocket inited');

// LogRocket.getSessionURL(sessionURL => {
//   Sentry.configureScope(scope => {
//     scope.setExtra('sessionURL', sessionURL);
//   });
// });

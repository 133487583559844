import {EntityState} from '@ngrx/entity';

import {ConversationEvent, ConversationStatus} from './conversation';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

export interface DateTimeObject {
  date: {
    year: number;
    month: number;
    day: number;
  };
  time: {
    hour: number;
    minute: number;
  };
}

export interface TimeObject {
  hour: number;
  minute: number;
}

export class WelcomeScript {
  tagId: string = '';
  text: string = '';

  constructor(json?: any) {
    if (json) {
      this.tagId = json.tagId || '';
      this.text = json.text || '';
    }
  }
}

export class AdditionalScript {
  name: string = '';
  text: string = '';
  showProfile: boolean = false;

  constructor(json?: any) {
    if (json) {
      this.name = json.name || '';
      this.text = json.text || '';
      this.showProfile = json.showProfile || false;
    }
  }
}

export class EmailTemplate {
  title: string = '';
  tagId: string = '';
  subject: string = '';
  body: string = '';
  fromName: string = '';
  fromEmail: string = '';
  replyTo: string = '';

  constructor(json?: any) {
    if (json) {
      this.title = json.title || '';
      this.tagId = json.tagId || '';
      this.subject = json.subject || '';
      this.body = json.body || '';
      this.fromName = json.fromName || '';
      this.fromEmail = json.fromEmail || '';
      this.replyTo = json.replyTo || '';
    }
  }
}

export enum QuestionType {
  TEXT = 'TEXT',
  CHOOSE_ONE = 'CHOOSE_ONE',
  CHOOSE_ANY = 'CHOOSE_ANY',
  DROP_DOWN = 'DROP_DOWN',
  DATE = 'DATE',
  ADDRESS = 'ADDRESS',
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
  INSTRUCTIONS = 'INSTRUCTIONS',
  COLUMN_BREAK = 'COLUMN_BREAK'
}

export class FormOption {
  id: number = 0;
  option: string = '';
  codeType: string = '';
  autoEmail: string = '';
  status: ConversationStatus;

  constructor(json?: any) {
    if (json) {
      this.id = json.id || (Math.floor(Math.random() * 1000000) + 1);
      this.option = json.option || '';
      this.codeType = json.codeType || '';
      this.autoEmail = json.autoEmail || '';
      this.status = json.status || ConversationStatus.Started;
    } else {
      this.id = Math.floor(Math.random() * 1000000) + 1;
      this.option = '';
      this.codeType = '';
      this.autoEmail = '';
      this.status = ConversationStatus.Started;
    }
  }

  static createForm(option:FormOption):UntypedFormGroup {
    return new UntypedFormGroup({
      id: new UntypedFormControl(option.id),
      option: new UntypedFormControl(option.option, Validators.required),
      codeType: new UntypedFormControl(option.codeType),
      autoEmail: new UntypedFormControl(option.autoEmail),
      status: new UntypedFormControl(option.status),
    });
  }
}

export class FormQuestion {
  id: number = 0;
  prompt: string = '';
  type: QuestionType = QuestionType.TEXT;
  options: FormOption[] = [];
  allowOther: boolean = false;
  otherPrompt: string = 'Other';
  placeholder: string;
  special: string = '';
  showIf: { questionId: number, regExp: string };
  required: boolean = false;
  prefill: { type:string, value?: string, daysAdjust?: number, copyResponseId?: number };

  constructor(json?: any) {
    if (json) {
      this.id = json.id || (Math.floor(Math.random() * 1000000) + 1);
      this.prompt = json.prompt || '';
      this.type = json.type || QuestionType.TEXT;
      this.options = [];
      this.allowOther = json.allowOther || false;
      this.otherPrompt = json.otherPrompt || 'Other';
      this.placeholder = json.placeholder || '';
      this.special = json.special || '';
      if (json.options && json.options.length > 0) {
        this.options = json.options.map(o => new FormOption(o));
      }
      this.showIf = json.showIf || null;
      this.required = json.required || false;
      this.prefill = json.prefill || null;
    } else {
      this.id = Math.floor(Math.random() * 1000000) + 1;
      this.prompt = '';
      this.type = QuestionType.TEXT;
      this.options = [];
      this.allowOther = false;
      this.otherPrompt = 'Other';
      this.placeholder = '';
      this.special = '';
      this.showIf = null;
      this.required = false;
      this.prefill = null;
    }
  }

  static createForm(question:FormQuestion):UntypedFormGroup {
    if (question.type === QuestionType.COLUMN_BREAK) {
      return new UntypedFormGroup({
        id: new UntypedFormControl(question.id),
        type: new UntypedFormControl(question.type),
        showIf: new UntypedFormGroup({
          questionId: new UntypedFormControl( (question.showIf) ? question.showIf.questionId : '' ),
          regExp: new UntypedFormControl( (question.showIf) ? question.showIf.regExp : '' )
        })
      });
    }

    const optionsArray = new UntypedFormArray([]);
    if (question.options) {
      question.options.forEach( (option:FormOption) => {
        optionsArray.push(FormOption.createForm(option));
      });
    }

    return new UntypedFormGroup({
      id: new UntypedFormControl(question.id),
      prompt: new UntypedFormControl(question.prompt, Validators.required),
      type: new UntypedFormControl(question.type, Validators.required),
      options: optionsArray,
      allowOther: new UntypedFormControl(question.allowOther),
      otherPrompt: new UntypedFormControl(question.otherPrompt),
      placeholder: new UntypedFormControl(question.placeholder),
      special: new UntypedFormControl(question.special),
      required: new UntypedFormControl(question.required),
      prefill: new UntypedFormGroup({
        type: new UntypedFormControl( question.prefill ? question.prefill.type : '' ),
        value: new UntypedFormControl( question.prefill ? question.prefill.value : '' ),
        daysAdjust: new UntypedFormControl( question.prefill ? question.prefill.daysAdjust : '' ),
        copyResponseId: new UntypedFormControl( question.prefill ? question.prefill.copyResponseId : '' )
      }),
      showIf: new UntypedFormGroup({
        questionId: new UntypedFormControl( (question.showIf) ? question.showIf.questionId : '' ),
        regExp: new UntypedFormControl( (question.showIf) ? question.showIf.regExp : '' )
      })
    });
  }
}

export class FormSection {
  id: number = 0;
  title: string = '';
  instructions: string = '';
  questions: FormQuestion[] = [];
  columnBreak: boolean = false;

  constructor(json?: any) {
    if (json) {
      this.id = json.id || (Math.floor(Math.random() * 1000000) + 1);
      this.title = json.title || '';
      this.instructions = json.instructions || '';
      this.questions = [];
      this.columnBreak = json.columnBreak || false;
      if (json.questions && json.questions.length > 0) {
        this.questions = json.questions.map(q => new FormQuestion(q));
      }
    } else {
      this.id = Math.floor(Math.random() * 1000000) + 1;
      this.title = '';
      this.instructions = '';
      this.questions = [];
      this.columnBreak = false;
    }
  }

  static createForm(section:FormSection):UntypedFormGroup {
    const questionsArray = new UntypedFormArray([]);
    if (section.questions) {
      section.questions.forEach( (question:FormQuestion) => {
        questionsArray.push(FormQuestion.createForm(question));
      });
    }

    return new UntypedFormGroup({
      id: new UntypedFormControl(section.id),
      title: new UntypedFormControl(section.title, Validators.required),
      instructions: new UntypedFormControl(section.instructions),
      questions: questionsArray,
      columnBreak: new UntypedFormControl(section.columnBreak),
    });
  }
}

export class Image {
  id: number = 0;
  name: string = '';
  url: string = '';

  constructor(json?: any) {
    if (json) {
      this.id = json.id || (Math.floor(Math.random() * 1000000) + 1);
      this.name = json.name || '';
      this.url = json.url || '';
    } else {
      this.id = Math.floor(Math.random() * 1000000) + 1;
      this.name = '';
      this.url = '';
    }
  }
}

export enum Frequency {
  NONE = 'Not Repeating',
  DAILY = 'Daily',
  WEEKLY = 'Weekly'
}

export interface StatusLabel {
  admin: string;
  user: string;
}

export interface StatusLabels {
  Pending: StatusLabel;
  Started: StatusLabel;
  Success: StatusLabel;
  Gave: StatusLabel;
  Declined: StatusLabel;
  OptOut: StatusLabel;
  WrongNumber: StatusLabel;
  BadNumber: StatusLabel;
  Maybe: StatusLabel;
  DeclinedOther: StatusLabel;
  Others: StatusLabel;
}

export interface ConvoButtons {
  name: string;
  status: ConversationStatus;
  event: ConversationEvent;
  color: 'info' | 'warning' | 'success' | 'danger' | 'primary' | 'secondary';
  detail: string;
  showToUser: boolean;
}

export interface Notifications {
  email: string | null;
  uid: string | null;
  anyMessageReceived: boolean;
  messageReceivedAfter: boolean;
  noteWritten: boolean;
  setGivingStatus: boolean;
}

export const DEFAULT_STATUS_LABELS: StatusLabels = {
  Pending: { admin: 'Not Started', user: 'Not Started' },
  Started: { admin: 'Started', user: 'Started' },
  Success: { admin: 'Giving', user: 'Giving' },
  Gave: { admin: 'Gave', user: 'Gave' },
  Declined: { admin: 'Declined', user: 'Declined' },
  OptOut: { admin: 'Opt-Out', user: 'Opt-Out' },
  WrongNumber: { admin: 'Wrong Number', user: 'Wrong Number' },
  BadNumber: { admin: 'Not Textable', user: 'Not Textable' },
  Maybe: { admin: 'Maybe', user: 'Maybe' },
  DeclinedOther: { admin: 'Declined - Other', user: 'Declined' },
  Others: { admin: 'Others', user: 'Others' },
};

export const DEFAULT_CONVO_BUTTONS: ConvoButtons[] = [
  { name: 'Called (No-Answer/VM)', status: ConversationStatus.Pending, event: ConversationEvent.CalledNoAnswer, color: 'info', detail: '', showToUser: true },
  { name: 'Called (Talked)', status: ConversationStatus.Pending, event: ConversationEvent.Called, color: 'warning', detail: '', showToUser: true },
  { name: 'Texted (Out of Parlance)', status: ConversationStatus.Pending, event: ConversationEvent.TextedPersonal, color: 'warning',detail: '', showToUser: true },
  { name: 'Emailed', status: ConversationStatus.Pending, event: ConversationEvent.Emailed, color: 'warning', detail: '', showToUser: true },
  { name: 'Giving', status: ConversationStatus.Success, event: ConversationEvent.Success, color: 'success', detail: '', showToUser: true },
  { name: 'Gave!', status: ConversationStatus.Gave, event: ConversationEvent.Gave, color: 'success', detail: '', showToUser: false },
  { name: 'Declined', status: ConversationStatus.Declined, event: ConversationEvent.Declined, color: 'danger', detail: '', showToUser: true },
  { name: 'Wrong Number', status: ConversationStatus.WrongNumber, event: ConversationEvent.Other, color: 'danger', detail: '', showToUser: true },
  { name: 'Do Not Contact', status: ConversationStatus.OptOut, event: ConversationEvent.OptOut, color: 'danger', detail: '', showToUser: true },
];

export const DEFAULT_CALLING_CONVO_BUTTONS: ConvoButtons[] = [
  { name: 'Not Started', status: ConversationStatus.Pending, event: ConversationEvent.CalledNoAnswer, color: 'info', detail: '', showToUser: true },
  { name: 'Attempted', status: ConversationStatus.Started, event: ConversationEvent.Called, color: 'warning', detail: '', showToUser: true },
  { name: 'Maybe', status: ConversationStatus.Maybe, event: ConversationEvent.Maybe, color: 'success', detail: '', showToUser: true },
  { name: 'Giving', status: ConversationStatus.Success, event: ConversationEvent.Success, color: 'success', detail: '', showToUser: true },
  { name: 'Gave!', status: ConversationStatus.Gave, event: ConversationEvent.Gave, color: 'success', detail: '', showToUser: false },
  { name: 'Declined', status: ConversationStatus.Declined, event: ConversationEvent.Declined, color: 'danger', detail: '', showToUser: true },
  { name: 'Declined Special', status: ConversationStatus.DeclinedOther, event: ConversationEvent.Declined, color: 'danger', detail: '', showToUser: false },
  { name: 'Wrong Number', status: ConversationStatus.WrongNumber, event: ConversationEvent.Other, color: 'danger', detail: '', showToUser: true },
  { name: 'Do Not Contact', status: ConversationStatus.OptOut, event: ConversationEvent.OptOut, color: 'danger', detail: '', showToUser: true },
];

export class Campaign {
  id?: string;
  name: string;
  startAt: DateTimeObject;
  endAt: DateTimeObject;
  welcomeScripts: WelcomeScript[];
  defaultWelcome: string;
  optOutScript: string;
  additionalScripts: AdditionalScript[];
  emailTemplates: EmailTemplate[];
  counts: { Pending: number, Started: number, Success: number, Maybe: number, Gave: number, Declined: number, OptOut: number, BadNumber: number, WrongNumber: number };
  extra: string;
  allowCustomBlastMessage: boolean;
  reminderContactedScript: string;
  reminderSilentScript: string;
  hidePhone: boolean;
  hideEmail: boolean;
  formSections: FormSection[];
  notifyEmail: string;
  billingPlan?: any;
  type: string;
  profileDisplay: string;
  profileCols: string[];
  extraTabs: AdditionalScript[];
  scheduled: {
    blast: {
      on:boolean,
      onlyAssigned:boolean,
      at: number
    },
    reminder: {
      on:boolean,
      onlyAssigned:boolean,
      allowDuplicates:boolean,
      sendStarter:boolean,
      at: number,
      frequency: Frequency,
      every: number,
      weekly: boolean[]
    }
  };
  emails: EmailTemplate[] = [];
  areaCodes: string[];
  images: Image[];
  messageStats?: {delivered?: number, received?: number, sent?: number, spam?: number, undelivered?: number, replied?: number};
  statuses: StatusLabels;
  buttons: ConvoButtons[];
  notifications: Notifications[];
  externalCalling: boolean;
  disableTexting: boolean;
  hideClassYear: boolean;
  useNickname: boolean;
  noLineLabels: boolean;

  constructor(jsonO?: any) {
    const now = new Date();

    if (jsonO) {
      const json = JSON.parse(JSON.stringify(jsonO));

      this.id = json.id || undefined;
      this.name = json.name || '';
      this.startAt = json.startAt || { date: {year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate()}, time: { hour: 9, minute: 0} };
      this.endAt = json.endAt || { date: {year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate()}, time: { hour: 21, minute: 0} };
      this.welcomeScripts = json.welcomeScripts ? json.welcomeScripts.map(o => ({...o})) : [];
      this.defaultWelcome = json.defaultWelcome || '';
      this.optOutScript = json.optOutScript || '';
      this.additionalScripts = json.additionalScripts || [];
      this.emailTemplates = json.emailTemplates || [];
      this.counts = json.counts || { Pending: 0, Started: 0, Success: 0, Maybe: 0, Gave:0, Declined: 0, OptOut: 0, BadNumber: 0, WrongNumber: 0 };
      this.extra = json.extra || '';
      this.allowCustomBlastMessage = json.allowCustomBlastMessage || false;
      this.reminderContactedScript = json.reminderContactedScript || '';
      this.reminderSilentScript = json.reminderSilentScript || '';
      this.hidePhone = json.hidePhone || false;
      this.hideEmail = json.hideEmail || false;
      this.formSections = json.formSections || [];
      this.notifyEmail = json.notifyEmail || '';
      this.type = json.type || 'standard';
      this.profileDisplay = json.profileDisplay || (this.type === 'calling' ? 'custom' : 'standard');
      this.profileCols = json.profileCols || ['','',''];
      this.extraTabs = json.extraTabs ? json.extraTabs.map(o => ({...o})) : [];
      this.externalCalling = json.externalCalling || false;
      this.disableTexting = json.disableTexting || false;
      this.hideClassYear = json.hideClassYear || false;
      this.useNickname = json.useNickname || false;
      this.noLineLabels = json.noLineLabels || false;
      this.scheduled = json.scheduled ?
        JSON.parse(JSON.stringify(json.scheduled)) : {
          blast: {
            on:false,
            onlyAssigned:false,
            at: new Date().getTime()
          },
          reminder: {
            on:false,
            onlyAssigned:false,
            allowDuplicates:false,
            sendStarter: false,
            at: new Date().getTime(),
            frequency: Frequency.NONE,
            every: 1,
            weekly: [false,false,false,false,false,false,false]
          }
      };
      this.emails = json.emails ? json.emails.map(o => ({...o})) : [];
      this.areaCodes = json.areaCodes || [];
      this.images = json.images ? json.images.map(o => ({...o})) : [];
      this.messageStats = json.messageStats || {};
      this.statuses = json.statuses || DEFAULT_STATUS_LABELS;
      this.buttons = json.buttons || (this.type === 'calling' ? DEFAULT_CALLING_CONVO_BUTTONS : DEFAULT_CONVO_BUTTONS);
      this.notifications = json.notifications || [];
    } else {
      this.name = '';
      this.welcomeScripts = [];
      this.optOutScript = '';
      this.startAt = { date: {year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate()}, time: { hour: 9, minute: 0} };
      this.endAt = { date: {year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate()}, time: { hour: 21, minute: 0} };
      this.additionalScripts = [];
      this.emailTemplates = [];
      this.counts = { Pending: 0, Started: 0, Success: 0, Maybe: 0, Gave: 0, Declined: 0, OptOut: 0, BadNumber: 0, WrongNumber: 0 };
      this.extra = '';
      this.allowCustomBlastMessage = false;
      this.reminderContactedScript = '';
      this.reminderSilentScript = '';
      this.hidePhone = false;
      this.hideEmail = false;
      this.formSections = [];
      this.notifyEmail = '';
      this.type = 'standard';
      this.profileDisplay = 'standard';
      this.profileCols = ['','',''];
      this.extraTabs = [];
      this.externalCalling = false;
      this.disableTexting = false;
      this.hideClassYear = false;
      this.useNickname = false;
      this.noLineLabels = false;
      this.scheduled = {
        blast: {
          on:false,
          onlyAssigned:false,
          at: new Date().getTime()
        },
        reminder: {
          on:false,
          onlyAssigned:false,
          allowDuplicates:false,
          sendStarter: false,
          at: new Date().getTime(),
          frequency: Frequency.NONE,
          every: 1,
          weekly: [false,false,false,false,false,false,false]
        }
      };
      this.emails = [];
      this.areaCodes = [];
      this.images = [];
      this.messageStats = {delivered: 0, received: 0, sent: 0, spam: 0, undelivered: 0, replied: 0};
      this.statuses = DEFAULT_STATUS_LABELS;
      this.buttons = DEFAULT_CONVO_BUTTONS;
      this.notifications = [];
    }
  }
}

export interface CampaignState extends EntityState<Campaign> { }

import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { serverTimestamp } from 'firebase/database';

import { AuthenticationService, OrgIdService } from './';

@Injectable({
  providedIn: 'root'
})
export class UserStateService {
  connected$: BehaviorSubject<any>;
  connectionSub: Subscription;

  constructor(
    private auth: AuthenticationService,
    private orgIdService: OrgIdService,
    private db: AngularFireDatabase,
  ) {
    this.connected$ = new BehaviorSubject(false);
  }

  startMonitoring() {
    this.auth.authInfo.subscribe( user => {
      if (!user || !user.uid) return;

      // Validate user is in this org
      this.db.object(`/organizations/${this.orgIdService.getOrgId()}/users/${user.uid}`).snapshotChanges().pipe(take(1)).subscribe(allowedUsers => {
        if (allowedUsers.payload.val()) {
          const uid = user.uid;

          // We'll create two constants which we will write to
          // the Realtime database when this device is offline
          // or online.
          const isOfflineForDatabase = {
              state: 'offline',
              last_changed: serverTimestamp(),
          };

          const isOnlineForDatabase = {
              state: 'online',
              last_changed: serverTimestamp(),
          };

          const userStatusDatabaseRef = this.db.database.ref(`/organizations/${this.orgIdService.getOrgId()}/userstatus/${uid}`);

          this.connectionSub = this.db.object('.info/connected').valueChanges().subscribe(val => {
            console.log('CONNECTION STATUS CHANGE', val);
            this.connected$.next(val);

            if (val) {
              // If we are currently connected, then use the 'onDisconnect()'
              // method to add a set which will only trigger once this
              // client has disconnected by closing the app,
              // losing internet, or any other means.
              userStatusDatabaseRef.onDisconnect().update(isOfflineForDatabase).then(function() {
                  // The promise returned from .onDisconnect().set() will
                  // resolve as soon as the server acknowledges the onDisconnect()
                  // request, NOT once we've actually disconnected:
                  // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect

                  // We can now safely set ourselves as 'online' knowing that the
                  // server will mark us as offline once we lose connection.
                  userStatusDatabaseRef.update(isOnlineForDatabase);
              });
            }
          });
        }
      });
    });
  }

  updateStatus(campaignId:string, status:any) {
    if (status.last_call_started) { status.last_call_started = serverTimestamp(); status.last_call_ended = 0; }
    if (status.last_call_ended) { status.last_call_ended = serverTimestamp(); }

    this.auth.authInfo.pipe(take(1)).subscribe( user => {
      if (user) {
        const uid = user.uid;
        // Validate user is in this org
        this.db.object(`/organizations/${this.orgIdService.getOrgId()}/users/${user.uid}`).snapshotChanges().pipe(take(1)).subscribe(allowedUsers => {
          if (allowedUsers.payload.val()) {
            this.db.database.ref(`/organizations/${this.orgIdService.getOrgId()}/userstatus/${uid}/status/${campaignId}`).update(status);
          }
        });
      }
    });
  }

  setOnline() {
    this.auth.authInfo.pipe(take(1)).subscribe( user => {
      if (user) {
        const uid = user.uid;
        // Validate user is in this org
        this.db.object(`/organizations/${this.orgIdService.getOrgId()}/users/${user.uid}`).snapshotChanges().pipe(take(1)).subscribe(allowedUsers => {
          if (allowedUsers.payload.val()) {
            this.db.database.ref(`/organizations/${this.orgIdService.getOrgId()}/userstatus/${uid}/state`).set('online');
          }
        });
      }
    });
  }

  async setOffline(): Promise<void> {
    return new Promise( (resolve) => {
      this.auth.authInfo
        .pipe(take(1))
        .subscribe(async (user) => {
        if (user) {
          const uid = user.uid;
          // Validate user is in this org
          this.db.object(`/organizations/${this.orgIdService.getOrgId()}/users/${user.uid}`)
            .snapshotChanges()
            .pipe(take(1))
            .subscribe(async (allowedUsers) => {
            if (allowedUsers.payload.val()) {
              await this.db.database.ref(`/organizations/${this.orgIdService.getOrgId()}/userstatus/${uid}/state`).set('offline');
            }
            resolve();
          });
        } else {
          console.log('no user');
          resolve();
        }
      });
    });
  }

  watchCallerStatus(campaignId:string):Observable<any> {
    return this.db.object(`/organizations/${this.orgIdService.getOrgId()}/userstatus`).snapshotChanges().pipe(
      map(data => data.payload.val() || {}),
      map(data => {
        const newData = [];
        Object.keys(data).forEach(uid => {
          const userData = data[uid];
          if (userData.status && userData.status[campaignId]) {
            newData.push({...userData, ...{id:uid}});
          }
        });
        return newData;
      })
    );
  }

  async retrieveCallStatus(number: string, callSid: string): Promise<any> {
    return new Promise((result: any) => {
      console.log('Getting call status', number, callSid);
      this.db.object(`/organizations/${this.orgIdService.getOrgId()}/callStatus/${number}/${callSid}/status`).snapshotChanges().pipe(take(1)).subscribe(snap => {
        if (snap.payload.val()) {
          console.log('Loaded', snap.payload.val());
          result(snap.payload.val());
        } else {
          console.log('No data');
          result({});
        }
      });
    });
  }
}

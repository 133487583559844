import { Observable } from 'rxjs';
import { Action } from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createSelector, createFeatureSelector } from '@ngrx/store';

import { ActionWithPayload, ContactActions } from '../actions';
import { Family, ContactState } from '../../model';

// Entity adapter
export const contactsAdapter = createEntityAdapter<Family>();
export const initialState: ContactState = contactsAdapter.getInitialState();

export function contactsReducer(state: ContactState = initialState, action: ActionWithPayload<any>) {
  switch(action.type) {
    case ContactActions.LOAD_ALL_SUCCESS:
      return contactsAdapter.setAll(action.payload, state);
    case ContactActions.ADDED:
      return contactsAdapter.addOne(action.payload, state);
    case ContactActions.MODIFIED:
      return contactsAdapter.updateOne({id: action.payload.id, changes: action.payload.changes}, state);
    case ContactActions.REMOVED:
      return contactsAdapter.removeOne(action.payload.id, state);
    default:
      return state;
  }
}

export function contactDictionary(state:any = {}, action: ActionWithPayload<Family[]>):{[key: string]: Family} {
  switch(action.type) {
    case ContactActions.LOAD_ALL_SUCCESS:
      const data: Family[] = action.payload;
      const dataDict: {[key: string]: Family} = {};
      data.forEach(obj => {
        dataDict[obj.id] = obj;
      });
      return dataDict;
    default:
      return state;
  }
};

export const selectContactsState = createFeatureSelector<ContactState>('contacts');

import { Injectable } from '@angular/core';
import { AuthenticationService } from './';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { catchError, takeWhile, timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthPhoneNumberService {

  constructor(
    private auth: AuthenticationService,
    private db: AngularFireDatabase,
    private firebaseFunctions: AngularFireFunctions,
  ) { }

  getValidatedPhoneNumber() {
    const raw = localStorage.getItem('validatedPhone');
    if (raw) {
      const json:any = JSON.parse(raw);
      if (json.phone) return json.phone;
    }
    return null;
  }

  setValidatedPhoneNumber(num: string) {
    const data = { phone: num, at: new Date().getTime() };
    localStorage.setItem('validatedPhone', JSON.stringify(data));
  }

  async placeCall(data:{org:string, campaignId:string, contactId:string, callNumber:string}) {
    const userNumber = this.getValidatedPhoneNumber();

    const userId = this.auth.getCurrentUser().id;
    if (userId && userId.length > 1 && userNumber && userNumber.length >= 10) {
      // Write request to database
      const itemRef = this.db.object(`/organizations/${data.org}/calllog/${userId}`);
      await itemRef.remove();

      const callRequest = {
        campaignId: data.campaignId,
        contactId: data.contactId,
        userNumber: userNumber,
        callNumber: data.callNumber,
        status: 'requested',
        at: new Date().getTime(),
      }
      await itemRef.set(callRequest);

      return itemRef.valueChanges().pipe(takeWhile( (v:any) => v && v.status !== 'completed', true));
      //
      // await new Promise( (resolve, reject) => {
      //   itemRef.valueChanges().pipe(
      //     catchError(err => { reject(err); throw err; }),
      //     takeWhile( (v:any) => v && v.status !== 'completed', true)).subscribe(val => {
      //     console.log("Got call status update", val);
      //     if (val && val.status === 'completed') resolve(val);
      //   });
      // });
      //
      // await itemRef.remove();

      // this.firebaseFunctions.httpsCallable('voiceCallRelay')({
      //   org: data.org,
      //   campaignId: data.campaignId,
      //   contactId: data.contactId,
      //   userNumber: userNumber,
      //   callNumber: data.callNumber
      // })
      // .toPromise().then(function(result:any) {
      //   // Read result of the Cloud Function.
      //   console.log("Call Success", result);
      // }).catch(function(error) {
      //   // Getting the Error details.
      //   console.log("Call Error", error);
      //   alert("There was an error placing the call. Please contact support for assistance!");
      // });
    }
  }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Store, Action } from '@ngrx/store';

import { map } from 'rxjs/operators';

import { Family } from '../model';
import { OrgIdService } from './org-id.service';
import { ContactActions, ConversationActions } from '../store/actions';
import { AppStore } from '../store/app.store';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(
    private db: AngularFireDatabase,
    private actions: ContactActions,
    private conversationActions: ConversationActions,
    private store: Store<AppStore>,
    private orgIdService: OrgIdService,
    private router: Router) {
  }

  loadAll() {
    return this.db.object(`/organizations/${this.orgIdService.getOrgId()}/contacts`).snapshotChanges();
  }

  getContacts(): Observable<Family[]> {
    return this.db.list<Family>(`/organizations/${this.orgIdService.getOrgId()}/contacts`).valueChanges();
  }

  saveContact(familyRo: Family, redirectOnSuccess = true) {
    const family = JSON.parse(JSON.stringify(familyRo));

    console.log('Saving', family);
    if ((family.id !== undefined) && (family.id.length > 0)) {
      // Update existing
      console.log('Updating', family.id);
      this.db.object(`/organizations/${this.orgIdService.getOrgId()}/contacts/${family.id}`).update(family).then(
        (retVal) => {
          console.log('Update success', retVal);
          this.store.dispatch(this.actions.saveSuccess());
          if (redirectOnSuccess) this.router.navigate(['/contacts']);
        }, (error: Error) => {
          console.log('Error pushing', error);
        }
      );
    } else {
      // Add new
      console.log('Adding new');
      this.db.list(`/organizations/${this.orgIdService.getOrgId()}/contacts`).push(family).then(
        (retVal) => {
          family.id = retVal.key;
          this.store.dispatch(this.actions.saveSuccess());
          if (redirectOnSuccess) this.router.navigate(['/contacts']);
        }, (error: Error) => {
          console.log('Error pushing new contact', error);
        }
      );
    }
  }

  saveContactThenPerformActions(data: {contact:Family, actions: Action[]}) {
    if ((!data.contact.id) || (data.contact.id === '-1')) {
      data.contact.id = this.db.database.ref().push().key;
      console.log('Got id for new contact', data.contact.id);
    }

    this.db.object(`/organizations/${this.orgIdService.getOrgId()}/contacts/${data.contact.id}`).update(data.contact).then(
      (retVal) => {
        console.log('Saved', data.contact, data.actions);
        this.store.dispatch(this.actions.saveSuccess());
        data.actions.forEach((nextAction) => {
          console.log('Saved contact, trigger action', nextAction);
          this.store.dispatch(nextAction);
        });
      }, (error: Error) => {
        console.log('Error pushing new contact', error);
      }
    );
  }

  loadCampaignsForContact(contactId:string):Observable<any> {
    return this.db.object(`/organizations/${this.orgIdService.getOrgId()}/campaigns-for-contact/${contactId}`)
      .snapshotChanges()
      .pipe(map(val => val.payload.val()));
  }
}

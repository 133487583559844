import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Observable, of } from 'rxjs';
import {catchError, switchMap, exhaustMap, map, mergeMap, tap, take, filter, debounceTime, throttleTime, auditTime} from 'rxjs/operators';

import { ActionWithPayload, ContactActions } from '../actions';
import { ContactService, OrgIdService } from '../../services';
import { Family } from '../../model';

import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Injectable()
export class ContactEffects {
  constructor(
    private actions$: Actions,
    private service: ContactService,
    private actions: ContactActions,
    private db: AngularFireDatabase,
    private orgIdService: OrgIdService,
    private firebaseFunctions: AngularFireFunctions,
  ) {}


  loadAll$ = createEffect(() => this.actions$.pipe(
    ofType(ContactActions.LOAD_ALL),
    switchMap(action => {
      return this.db.object(`/organizations/${this.orgIdService.getOrgId()}/contacts`).snapshotChanges();
    }),
    catchError( (err, caught) => {
      console.warn('Error in ContactEffects.loadAll$', err, caught);
      return of(null);
    }),
    filter(v => !!v),
    auditTime(250),
    map(action => {
      const obj = action.payload.val() || {};
      return {
        type: `[Contact] ${action.type}`,
        payload: Object.keys(obj).map(key => ({id:key, ...obj[key]}))
      };
    })
  ));


  saveContact$ = createEffect(() => this.actions$.pipe(
    ofType<ActionWithPayload<{family: Family, redirectOnSuccess?: boolean}>>(ContactActions.SAVE),
    tap((action) => this.service.saveContact(action.payload.family, action.payload.redirectOnSuccess)),
    filter(() => false)
  ));


  saveContactThenPerformActions$ = createEffect(() => this.actions$.pipe(
    ofType<ActionWithPayload<{contact:Family, actions:Action[]}>>(ContactActions.SAVE_THEN_PERFORM_ACTIONS),
    tap((action) => {
      this.service.saveContactThenPerformActions({contact: action.payload.contact, actions: action.payload.actions});
    }),
    filter(() => false)
  ));


  deleteContacts$ = createEffect(() => this.actions$.pipe(
    ofType<ActionWithPayload<{contactIds: any}>>(ContactActions.DELETE_CONTACTS),
    tap(async (action) => {
      const data = action.payload;
      console.log('Deleting contacts', data.contactIds);

      try {
        const result = await this.firebaseFunctions.httpsCallable('removeContacts')({org: this.orgIdService.getOrgId(), contactIds: data.contactIds}).toPromise();
        return true;
      } catch (err) {
        console.log('An error occurred deleting the selected contacts', err);
      }
      alert('An unknown error occurred deleting the selected contacts');
    }),
    filter(() => false)
  ));
}

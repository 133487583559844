import { Observable } from 'rxjs';
import { Action } from '@ngrx/store';
import * as _ from 'lodash';

import { ActionWithPayload, ConversationActions, MessageActions } from '../actions';

export function userToCampaignConversationsReducer(state: any = {}, action: ActionWithPayload<any>) {
  switch(action.type) {
    case ConversationActions.LOAD_CONVERSATIONS_FOR_USER_SUCCESS:
      // console.log("****** Load convo for user success", action.payload);
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export function conversationReducer(state: any = {}, action: ActionWithPayload<any>):any {
  switch(action.type) {
    case MessageActions.LOAD_ALL_FOR_CONVERSATION_SUCCESS:
      const v1 = JSON.parse(JSON.stringify(action.payload));
      if ((v1.messages === null) || (v1.messages === undefined)) v1.messages = {};
      const obj1 = { ...state[v1.campaignId] } || {};
      if (!obj1[v1.contactId]) obj1[v1.contactId] = {};
      if (!obj1[v1.contactId].messages) obj1[v1.contactId].messages = {};
      obj1[v1.contactId] = { ...obj1[v1.contactId], ...{ messages: { ...v1.messages } } };
      // console.log('***** Load all success', Object.assign({}, state, { [v1.campaignId]:obj1 }));
      return { ...state, [v1.campaignId]:obj1 };
    case ConversationActions.LOAD_SUCCESS:
      // console.log("***** Load success", action);
      const v = action.payload;
      let obj = JSON.parse(JSON.stringify(state[v.campaignId] || {}));
      try {
        if (!obj[v.contactId]) obj[v.contactId] = {};
        const newObj = { ...obj[v.contactId], ...v.value };
        obj = { ...obj, [v.contactId]: newObj };
        if (!obj[v.contactId].messages) obj[v.contactId].messages = {};
        if (! (v && v.value && v.value.user) ) delete obj[v.contactId].user;
      } catch (e) {
        console.error('Error loading conversation success', v.contactId, obj[v.contactId], e);
        delete obj[v.contactId];
      }
      return { ...state, [v.campaignId]: obj };
    case ConversationActions.LOAD_SUCCESS_BATCH:
      // console.log("***** Load batch success", action);
      const v2 = action.payload;
      const newState = { ...state };
      for(let i=0; i<v2.length; i++) {
        const data = v2[i];
        let obj2 = state[data.campaignId] || {};
        try {
          if (!obj2[data.contactId]) obj2[data.contactId] = {};
          const newObj = { ...obj2[data.contactId], ...v.value };
          obj2 = { ...obj2, [data.contactId]: newObj };
          if (!obj2[data.contactId].messages) obj2[data.contactId].messages = {};
          if (!data.value.user) delete obj2[data.contactId].user;
        } catch (e) {
          console.error('Error loading conversation batch success', data.contactId, obj2[data.contactId]);
          delete obj2[data.contactId];
        }
      }
      // console.log('***** Load batch success', newState);
      return newState;
    case ConversationActions.ADD_NOTE:
      console.log('***ADDING NOTE***', action);
      const newNote = {
        on: new Date().getTime(),
        text: action.payload.note,
        system: false,
        by: {
          firstName: action.payload.user.contactInfo.lastName,
          lastName: action.payload.user.contactInfo.firstName,
          id: action.payload.user.id
        }
      };

      return {
        ...state,
        [action.payload.campaignId]: {
          ...state[action.payload.campaignId],
          [action.payload.contactId]: {
            ...state[action.payload.campaignId][action.payload.contactId],
            notes: state[action.payload.campaignId][action.payload.contactId].notes ? [...state[action.payload.campaignId][action.payload.contactId].notes, newNote] : [newNote]
          }
        }
       };
    default:
      return state;
  }
}

// export function conversationsReducer(state: any = {}, action: ActionWithPayload<any>):any {
//   switch(action.type) {
//     case ConversationActions.LOAD_FOR_CAMPAIGN_SUCCESS:
//       // console.log("Updating conversations in store", action, state);
//       if (action != null) {
//         return { ...state, [action.payload.campaignId]: action.payload.value };
//       }
//       return state;
//     default:
//       return state;
//   }
// }

export function conversationsSummariesReducer(state: any = {}, action: ActionWithPayload<any>):any {
  switch(action.type) {
    case ConversationActions.LOAD_SUMMARIES_FOR_CAMPAIGN_SUCCESS:
      // console.log("Updating conversations in store", action, state);
      if (action != null) {
        return { ...state, [action.payload.campaignId]: action.payload.value };
      }
      return state;
    default:
      return state;
  }
}

export function unreadReducer(state: any = {}, action: ActionWithPayload<any>):any {
  switch(action.type) {
    case ConversationActions.LOAD_UNREADS_SUCCESS:
      if (action != null) {
        return { ...state, [action.payload.campaignId]: action.payload.value };
      }
      return state;
    case ConversationActions.LOAD_UNREAD_SUCCESS:
      // console.log('Updating UNREAD 1', action, state);
      if (action != null) {
        return { ...state, [action.payload.campaignId]: {...state[action.payload.campaignId], [action.payload.contactId]:action.payload.unread } };
      }
      return state;
    default:
      return state;
  }
}

import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidator, ValidationErrors } from '@angular/forms';
import { Observable, of, timer } from 'rxjs';
import { delay, catchError, map, switchMap, tap } from 'rxjs/operators';

import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Injectable({ providedIn: 'root' })
export class UniqueOrgIdValidator implements AsyncValidator {
  constructor(
    private firebaseFunctions: AngularFireFunctions
  ) {}

  validate(
    ctrl: AbstractControl
  ): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {

    return timer(1000).pipe(switchMap(() => {
      return this.firebaseFunctions.httpsCallable('checkOrgIdAvailablity')({orgId: ctrl.value}).toPromise().then(function(result:any) {
        // Read result of the Cloud Function.
        console.log("Got result from avail check", result);
        return (result && result.available) ? null : { uniqueOrgId: true };
      }).catch(function(error) {
        // Getting the Error details.
        console.log("Got error from avail check", error);
        var code = error.code;
        var message = error.message;
        var details = error.details;
        return { networkError: true };
      });
    }));

    // return timer(1000).pipe(switchMap(() => {
    //   return of(ctrl.value == 'zifty').pipe(
    //     tap(() => { console.log("Validating", ctrl.value); }),
    //     delay(400)
    //   ).pipe(
    //     map(isTaken => (isTaken ? { uniqueOrgId: true } : null)),
    //     catchError(() => null)
    //   );
    // }));

    // return of(ctrl.value == 'zifty').pipe(
    //   tap(() => { console.log("Validating", ctrl.value); }),
    //   delay(400)
    // ).pipe(
    //   map(isTaken => (isTaken ? { uniqueOrgId: true } : null)),
    //   catchError(() => null)
    // );

    // return pipe(tap(() => {
    //   console.log("Validating", ctrl.value);
    //   return ctrl.value == 'zifty';
    // }),of(ctrl.value == 'zifty')).pipe(delay(400)).pipe(
    //   map(isTaken => (isTaken ? { uniqueOrgId: true } : null)),
    //   catchError(() => null)
    // );
  }
}

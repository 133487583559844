import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject} from 'rxjs';
import { take } from 'rxjs/operators';

import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { User as firebaseUser } from 'firebase/auth';
import { Store } from '@ngrx/store';
import firebase from 'firebase/compat/app';
import 'firebase/auth';

import { User, Roles, Status } from '../model';
import { UserActions } from '../store/actions';

import { AppStore } from '../store/app.store';
// @ts-ignore
import LogRocket from 'logrocket';

declare var FS:any;

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public authInfo: Observable<firebaseUser>;
  public authState: any = null;
  public currentUser: User;
  public currentUserObservable: BehaviorSubject<User>;
  public status: BehaviorSubject<string>;
  public redirectUrl: string;

  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
    private store: Store<AppStore>,
    private userActions: UserActions,
    private db: AngularFireDatabase
  ) {
    this.status = new BehaviorSubject('loading');
    this.currentUserObservable = new BehaviorSubject(undefined);

    this.authInfo = this.afAuth.authState;
    this.store.select('user').subscribe(v => {
      this.currentUser = v;
      this.currentUserObservable.next(this.currentUser);

      if (this.status.getValue() === 'requested') {
        this.status.next('ready');
      }
    });

    this.afAuth.authState.subscribe((auth) =>  {
      this.authState = auth;
      if (this.authState && this.authState.uid) {
        if (LogRocket) {
          console.log('Update LogRocket identity');
          LogRocket.identify(this.authState.uid, {
            name: this.authState.displayName,
            email: this.authState.email,
          });
        }

        this.status.next('requested');
        this.store.dispatch(this.userActions.loggedIn(this.authState.uid));
      } else {
        this.status.next('ready');
      }
    });
  }

  async getBearerToken() {
    const user = await this.afAuth.currentUser;
    return user.getIdToken();
  }
  getStatus():BehaviorSubject<string> {
    return this.status;
  }

  getCurrentUser():User {
    return this.currentUser;
  }

  isPasswordChangeRequired():boolean {
    return (this.currentUser && this.currentUser.forceChangePassword);
  }

  isRoleAllowed(role:Roles):boolean {
    if (!this.currentUser || !this.currentUser.role) return false;

    switch(role) {
      case Roles.User:
        if (this.currentUser.role === Roles.User) return true;
      // tslint:disable-next-line:no-switch-case-fall-through
      case Roles.Admin:
        if (this.currentUser.role === Roles.Admin) return true;
      // tslint:disable-next-line:no-switch-case-fall-through
      case Roles.Owner:
        if (this.currentUser.role === Roles.Owner) return true;
      // tslint:disable-next-line:no-switch-case-fall-through
      default:
        return false;
    }
  }

  async reauthenticate(currentPassword:string) {
    const user = await this.afAuth.currentUser;
    const cred = firebase.auth.EmailAuthProvider.credential(
        user.email, currentPassword);
    return user.reauthenticateWithCredential(cred);
  }

  resetPassword(email:string) {
    return this.afAuth.sendPasswordResetEmail(email);
  }

  async changePassword(currentPassword:string, newPassword:string) {
    await this.reauthenticate(currentPassword);

    const user = await this.afAuth.currentUser;

    await user.updatePassword(newPassword);
    this.currentUser.forceChangePassword = false;
    this.currentUser.status = Status.Active;
    this.store.dispatch(this.userActions.save(this.currentUser));

    if (this.redirectUrl) {
      this.router.navigate([this.redirectUrl]);
      this.redirectUrl = null;
    }
    return true;
  }

  async changeEmail(currentPassword:string, newEmail:string) {
    await this.reauthenticate(currentPassword);
    const user = await this.afAuth.currentUser;
    await user.updateEmail(newEmail);
    await this.verifyEmail();
  }

  async verifyEmail() {
    const user = await this.afAuth.currentUser;
    return user.sendEmailVerification();
  }

  async updateProfile(userData:User) {
    const user = await this.afAuth.currentUser;
    await user.updateProfile({
      displayName: userData.displayName,
      photoURL: user.photoURL
    });

    console.log('Update success');
    return this.userActions.save(userData);
  }

  registerWithEmailPassword(email: string, password: string) {
    this.afAuth.createUserWithEmailAndPassword(email, password)
      .then(user => {
        console.log('Registration successful', user);
        this.router.navigate(['/']);
      })
      .catch(error => {
        console.log('Registration failed', error.code, error.message, error);
      });
  }

  loginCustomToken(token:string) {
    this.afAuth.signInWithCustomToken(token).then(user => {
      console.log('Custom token sign in successful', user);
      // Load user's organization
      this.db.object(`/users/${user.user.uid}`).snapshotChanges().pipe(take(1)).subscribe(v => {
        // console.log('Got user data', v.payload.val());
        this.currentUser = new User(v.payload.val());
        this.currentUserObservable.next(this.currentUser);
        const orgId = Object.keys(this.currentUser.organizations)[0];
        console.log('Using org id ' + orgId, this.isRoleAllowed(Roles.Admin));
        this.router.navigate([(this.isRoleAllowed(Roles.Admin)) ? 'admin':'go', orgId]);
      });
    });
  }

  loginViaEmailPassword(email: string, password: string, noNavigate:boolean = false) {
    return this.afAuth.signInWithEmailAndPassword(email, password)
      .then(user => {
        console.log('Sign in successful', user);
        // Load user's organization
        return new Promise( (resolve) => {
          this.db.object(`/users/${user.user.uid}`).snapshotChanges().pipe(take(1)).subscribe(v => {
            // console.log('Got user data', v.payload.val());
            this.currentUser = new User(v.payload.val());
            this.currentUserObservable.next(this.currentUser);
            const orgId = Object.keys(this.currentUser.organizations)[0];
            console.log('Using org id ' + orgId, this.isRoleAllowed(Roles.Admin));
            if (!noNavigate) this.router.navigate([(this.isRoleAllowed(Roles.Admin)) ? 'admin':'go', orgId]);
            resolve(orgId);
          });
        });
      });
  }

  loginViaGoogle() {
    this.afAuth.signInWithPopup(new firebase.auth.GoogleAuthProvider())
      .then((user) => { console.log(user); });
  }

  logout() {
    this.afAuth.signOut().then(() => {
      console.log('logged out');
      this.router.navigate(['/']);
      window.location.reload();
    });
  }
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Observable, of } from 'rxjs';
import {catchError, switchMap, map, tap, filter, throttleTime, mergeMap} from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { AppStore } from '../app.store';
import { OrgIdService } from '../../services';
import { Conversation, ConversationStatus, Family, ConversationEvent, Note, User } from '../../model/';
import { ActionWithPayload, ConversationActions, MessageActions } from '../actions';

import {AngularFireDatabase, SnapshotAction} from '@angular/fire/compat/database';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Injectable()
export class ConversationEffects {
  monitoring:any;

  constructor(
    private actions$: Actions,
    private conversationActions: ConversationActions,
    private messageActions: MessageActions,
    private db: AngularFireDatabase,
    private firebaseFunctions: AngularFireFunctions,
    private store: Store<AppStore>,
    private orgIdService: OrgIdService
  ) {
    this.monitoring = {};
  }


  loadSummariesForCampaign$ = createEffect(() => this.actions$.pipe(
    ofType<ActionWithPayload<string>>(ConversationActions.LOAD_SUMMARIES_FOR_CAMPAIGN),
    switchMap(action => {
      return this.db.object(`/organizations/${this.orgIdService.getOrgId()}/convosummary/${action.payload}`).snapshotChanges();
    }),
    catchError( (err, caught) => {
      console.warn('Error in ConversationEffects.loadSummariesForCampaign$', err, caught);
      return of(null);
    }),
    filter(v => !!v),
    throttleTime(500, undefined, { leading: true, trailing: true }),
    map(action => {
      const obj = action.payload.val();
      return {
        type: ConversationActions.LOAD_SUMMARIES_FOR_CAMPAIGN_SUCCESS,
        payload: { campaignId: action.key, value: obj }
      };
    })
  ));


  load$ = createEffect(() => this.actions$.pipe(
    ofType<ActionWithPayload<{campaignId:string, contactId:string}>>(ConversationActions.LOAD),
    mergeMap(action => {
      const key = `/organizations/${this.orgIdService.getOrgId()}/conversations/${action.payload.campaignId}/${action.payload.contactId}`;
      if (!this.monitoring[key]) {
        this.monitoring[key] = this.db.object(key)
          .snapshotChanges()
          .pipe(map( (newValue) => ({campaignId: action.payload.campaignId, contactId: action.payload.contactId, value: (<any>newValue).payload.val() }) ));
      }
      return this.monitoring[key];
    }),
    catchError( (err, caught) => {
      console.warn('Error in ConversationEffects.load$', err, caught);
      return of(null);
    }),
    filter(v => !!v),
    map(action => {
      return {
        type: ConversationActions.LOAD_SUCCESS,
        payload: action
      };
    })
  ));


  loadBatch$ = createEffect(() => this.actions$.pipe(
    ofType<ActionWithPayload<{campaignId:string, contactId:string}[]>>(ConversationActions.LOAD_BATCH),
    switchMap(action => {
      return action.payload.map( convo => {
        const key = `/organizations/${this.orgIdService.getOrgId()}/conversations/${convo.campaignId}/${convo.contactId}`;
        if (!this.monitoring[key]) {
          this.monitoring[key] = this.db.object(key).snapshotChanges()
            .pipe(map( (newValue) => {
              const result = [];
              for(let i=0; i<action.payload.length; i++) {
                result.push({campaignId: action.payload[i].campaignId, contactId: action.payload[i].contactId, value: (<any>newValue)[i].val() });
              }
              return result;
            } ));
        }
        return this.monitoring[key];
      });
    }),
    catchError( (err, caught) => {
      console.warn('Error in ConversationEffects.loadBatch$', err, caught);
      return of(null);
    }),
    filter(v => !!v),
    map(action => {
      // console.log(action);
      return {
        type: ConversationActions.LOAD_SUCCESS_BATCH,
        payload: action
      };
    })
  ));


  startTextWithPrimary$ = createEffect(() => this.actions$.pipe(
    ofType<ActionWithPayload<{campaignId:string, contact:Family, userId: string}[]>>(ConversationActions.START_TEXT_PRIMARY),
    tap((action) => {
      // console.log("Starting conversations", action.payload);

      const convos:Conversation[] = action.payload.map(c => {
        return {
          campaignId: c.campaignId,
          user: { id: c.userId },
          contact: { id: c.contact.id }
        };
      });
      // console.log("Conversations to send to func", convos);

      this.firebaseFunctions.httpsCallable('startTextConversation')({org: this.orgIdService.getOrgId(), conversations: convos}).toPromise().then(function(result:any) {
        // Read result of the Cloud Function.
        // console.log("Got result from start conversations", result);
        if (result && result.success) return true;

        console.log('Start conversations error', result);
        alert('An unknown error occurred starting conversations');
      }).catch(function(error) {
        // Getting the Error details.
        console.log('Got error from start conversations', error);
        alert('Error!\n' + error.message);
      });
    }),
    filter(() => false)
  ));


  loadUnreads = createEffect(() => this.actions$.pipe(
    ofType<ActionWithPayload<{campaignId:string}>>(ConversationActions.LOAD_UNREADS),
    switchMap(action => {
      return this.db.object(`/organizations/${this.orgIdService.getOrgId()}/unreads/${action.payload.campaignId}`).snapshotChanges();
    }),
    catchError( (err, caught) => {
      console.warn('Error in ConversationEffects.loadUnreads$', err, caught);
      return of(null);
    }),
    filter(v => !!v),
    map(action => {
      const obj = action.payload.val();
      return {
        type: ConversationActions.LOAD_UNREADS_SUCCESS,
        payload: { campaignId: action.key, value: obj }
      };
    })
  ));


  loadUnread = createEffect(() => this.actions$.pipe(
    ofType<ActionWithPayload<{campaignId:string, contactId:string}>>(ConversationActions.LOAD_UNREAD),
    mergeMap(async action => {
      const snap = await this.db.database.ref(`/organizations/${this.orgIdService.getOrgId()}/unreads/${action.payload.campaignId}/${action.payload.contactId}`).once('value');
      return {
        type: ConversationActions.LOAD_UNREAD_SUCCESS,
        payload: { campaignId: action.payload.campaignId, contactId: action.payload.contactId, unread: snap.val() }
      };
    })
  ));


  markRead$ = createEffect(() => this.actions$.pipe(
    ofType<ActionWithPayload<{campaignId:string, contactId: string}>>(ConversationActions.MARK_READ),
    map(async action => {
      await this.db.object(`/organizations/${this.orgIdService.getOrgId()}/unreads/${action.payload.campaignId}/${action.payload.contactId}`).set(false);
      await this.db.object(`/organizations/${this.orgIdService.getOrgId()}/conversations/${action.payload.campaignId}/${action.payload.contactId}/unread`).set(false);
    })
  ), {dispatch: false});


  setViewing$ = createEffect(() => this.actions$.pipe(
    ofType<ActionWithPayload<{campaignId:string, contactId: string, user: User}>>(ConversationActions.SET_VIEWING),
    map(async action => {
      // console.log('SET VIEW', action.payload.contactId, action.payload.user.id);
      const curViewTimeSnap = await this.db.database.ref(`/organizations/${this.orgIdService.getOrgId()}/conversations/${action.payload.campaignId}/${action.payload.contactId}/viewing/${action.payload.user.id}`).once('value');
      const curViewTime = curViewTimeSnap.val() || 0;

      const viewTime = new Date().getTime();
      if (curViewTime < (viewTime - (5 * 60 * 1000))) {
        // console.log('SET VIEW TIME', action.payload.contactId, action.payload.user.id, curViewTime, viewTime);
        await this.db.object(`/organizations/${this.orgIdService.getOrgId()}/conversations/${action.payload.campaignId}/${action.payload.contactId}/viewing/${action.payload.user.id}`)
          .set(viewTime);
      }
    })
  ), {dispatch: false});


  clearViewing$ = createEffect(() => this.actions$.pipe(
    ofType<ActionWithPayload<{campaignId:string, contactId: string, user: User}>>(ConversationActions.CLEAR_VIEWING),
    map(async action => {
      console.log('CLEAR VIEW', action.payload.contactId, action.payload.user.id);
      await this.db.object(`/organizations/${this.orgIdService.getOrgId()}/conversations/${action.payload.campaignId}/${action.payload.contactId}/viewing/${action.payload.user.id}`)
        .remove();
    })
  ), {dispatch: false});


  setNextAttemptAfterEffect$ = createEffect(() => this.actions$.pipe(
    ofType<ActionWithPayload<{campaignId: string, contactId: string, after: number}>>(ConversationActions.SET_NEXT_ATTEMPT_AFTER),
    tap(async (action) => {
      console.log('Set next attempt after', action.payload.campaignId, action.payload.contactId, action.payload.after);
      await this.db.object(`/organizations/${this.orgIdService.getOrgId()}/conversations/${action.payload.campaignId}/${action.payload.contactId}/nextAttemptAfter`).set(action.payload.after);
    })
  ), {dispatch: false});

  updateLocalContact$ = createEffect( () => this.actions$.pipe(
    ofType<ActionWithPayload<{campaignId: string, contactId: string, family: Family}>>(ConversationActions.UPDATE_LOCAL_CONTACT),
    tap(async (action) => {
      console.log('Updating contact in campaign only', action.payload);

      await this.db.object(`/organizations/${this.orgIdService.getOrgId()}/conversations/${action.payload.campaignId}/${action.payload.contactId}/contact/family/contacts`)
        .set(action.payload.family.contacts);
    })
  ), { dispatch: false });

  recordAttemptEffect$ = createEffect(() => this.actions$.pipe(
    ofType<ActionWithPayload<{campaignId: string, contactId: string, attempt: any}>>(ConversationActions.RECORD_ATTEMPT),
    tap(async (action) => {
      console.log('Recording attempt', action.payload.campaignId, action.payload.contactId);

      await this.db.object(`/organizations/${this.orgIdService.getOrgId()}/conversations/${action.payload.campaignId}/${action.payload.contactId}/lastAttemptDate`).set(new Date().getTime());
      const c = await this.db.database.ref(`/organizations/${this.orgIdService.getOrgId()}/conversations/${action.payload.campaignId}/${action.payload.contactId}/attemptCount`).once('value');
      const count = c.val() || 0;
      await this.db.object(`/organizations/${this.orgIdService.getOrgId()}/conversations/${action.payload.campaignId}/${action.payload.contactId}/attemptCount`).set(count+1);
      const attempt = action.payload.attempt;
      if (attempt && attempt.date) {
        await this.db.object(`/organizations/${this.orgIdService.getOrgId()}/conversations/${action.payload.campaignId}/${action.payload.contactId}/attempts/${attempt.date}`).set(attempt);
      }
    })
  ), {dispatch: false});


  updateForms$ = createEffect(() => this.actions$.pipe(
    ofType<ActionWithPayload<{campaignId: string, contactId: string, forms: object}>>(ConversationActions.UPDATE_FORMS),
    tap((action) => {
      console.log('Updating conversation forms', action.payload.campaignId, action.payload.contactId, action.payload.forms, `/organizations/${this.orgIdService.getOrgId()}/conversations/${action.payload.campaignId}/${action.payload.contactId}/forms`);
      const updateObj:any = action.payload.forms;
      this.db.object(`/organizations/${this.orgIdService.getOrgId()}/conversations/${action.payload.campaignId}/${action.payload.contactId}/forms`).update(updateObj).then(
        (retVal) => {
          console.log('Forms updated success', retVal);
        }, (error: Error) => {
          console.log('Error pushing', error);
        }
      );
    }),
    filter(() => false)
  ));

  updateSpecificFormField$ = createEffect(() => this.actions$.pipe(
    ofType<ActionWithPayload<{campaignId: string, contactId: string, formSection: number, formQuestion: number, formValue: string}>>(ConversationActions.UPDATE_SPECIFIC_FORM_FIELD),
    tap((action) => {
      console.log('Updating specific conversation form field', action.payload.campaignId, action.payload.contactId, action.payload.formSection, action.payload.formQuestion, action.payload.formValue, `/organizations/${this.orgIdService.getOrgId()}/conversations/${action.payload.campaignId}/${action.payload.contactId}/forms/${action.payload.formSection}/${action.payload.formQuestion}`);
      if (action.payload.formSection !== undefined && action.payload.formQuestion !== undefined && action.payload.formValue !== undefined && action.payload.formValue !== null) {
        this.db.object(`/organizations/${this.orgIdService.getOrgId()}/conversations/${action.payload.campaignId}/${action.payload.contactId}/forms/${action.payload.formSection}/${action.payload.formQuestion}`).update(action.payload.formValue).then(
          (retVal) => {
            console.log('Forms updated success', retVal);
          }, (error: Error) => {
            console.log('Error pushing', error);
          }
        );
      }
    }),
    filter(() => false)
  ));

  updateLastSentAt$ = createEffect(() => this.actions$.pipe(
    ofType<ActionWithPayload<{campaignId: string, contactId: string, lastMessageSentAt: number}>>(ConversationActions.UPDATE_LAST_SENT),
    tap((action) => {
      console.log('Updating conversation last sent at', action.payload.campaignId, action.payload.contactId, action.payload.lastMessageSentAt);
      this.db.object(`/organizations/${this.orgIdService.getOrgId()}/conversations/${action.payload.campaignId}/${action.payload.contactId}/lastMessageSentAt`)
        .set(action.payload.lastMessageSentAt)
        .then(
        (retVal) => {
          console.log('Last sent at updated success', retVal);
        }, (error: Error) => {
          console.log('Error pushing', error);
        }
      );
    }),
    filter(() => false)
  ));

  updateStatus$ = createEffect(() => this.actions$.pipe(
    ofType<ActionWithPayload<{campaignId: string, contactId: string, status: ConversationStatus, successData?: object}>>(ConversationActions.UPDATE_STATUS),
    tap((action) => {
      console.log('Updating conversation status', action.payload.campaignId, action.payload.contactId, action.payload.status, `/organizations/${this.orgIdService.getOrgId()}/conversations/${action.payload.campaignId}/${action.payload.contactId}`);
      const updateObj:any = { status:action.payload.status, statusDate: new Date().getTime() };
      if (action.payload.successData) {
        updateObj.successData = action.payload.successData;
      }
      this.db.object(`/organizations/${this.orgIdService.getOrgId()}/conversations/${action.payload.campaignId}/${action.payload.contactId}`).update(updateObj).then(
        (retVal) => {
          console.log('Status updated success', retVal);
        }, (error: Error) => {
          console.log('Error pushing', error);
        }
      );
    }),
    filter(() => false)
  ));


  recordEvent$ = createEffect(() => this.actions$.pipe(
    ofType<ActionWithPayload<{campaignId: string, contactId: string, event: ConversationEvent, data?: object, user?: User}>>(ConversationActions.RECORD_EVENT),
    tap((action) => {
      console.log('Recording conversation event', action.payload.campaignId, action.payload.contactId, action.payload.event, action.payload.data, `/organizations/${this.orgIdService.getOrgId()}/conversations/${action.payload.campaignId}/${action.payload.contactId}`, action.payload);
      const eventObj:any = { event:action.payload.event, on: new Date().getTime() };
      if (action.payload.user) {
        // console.log('adding user');
        eventObj.by = {
          id: action.payload.user.id,
          firstName: action.payload.user.contactInfo.firstName,
          lastName: action.payload.user.contactInfo.lastName
        };
      }
      if (action.payload.data) {
        eventObj.data = action.payload.data;
      }
      console.log('PUSHING EVENT', eventObj);
      this.db.list(`/organizations/${this.orgIdService.getOrgId()}/conversations/${action.payload.campaignId}/${action.payload.contactId}/history`).push(eventObj).then(
        (retVal) => {
          console.log('Record event success', retVal);
        }, (error: Error) => {
          console.log('Error pushing', error);
        }
      );
    }),
    filter(() => false)
  ));


  addNote$ = createEffect(() => this.actions$.pipe(
    ofType<ActionWithPayload<{campaignId: string, contactId: string, note: string}>>(ConversationActions.ADD_NOTE),
    tap(async (action) => {
      const data = action.payload;
      console.log('Adding note to conversation', data.campaignId, data.contactId, data.note, `/organizations/${this.orgIdService.getOrgId()}/conversations/${data.campaignId}/${data.contactId}`);

      try {
        const result = await this.firebaseFunctions.httpsCallable('addNote')({org: this.orgIdService.getOrgId(), campaignId: data.campaignId, contactId: data.contactId, note: data.note}).toPromise();
        return true;
      } catch (err) {
        console.log('An error occurred adding the note to this conversation', err);
      }
      alert('An unknown error occurred adding the note to this conversation');
    }),
    filter(() => false)
  ));


  deleteConversations$ = createEffect(() => this.actions$.pipe(
    ofType<ActionWithPayload<{campaignId: string, contactIds: any}>>(ConversationActions.DELETE_CONVERSATIONS),
    tap(async (action) => {
      const data = action.payload;
      console.log('Deleting conversations', data.campaignId, data.contactIds);

      try {
        const result = await this.firebaseFunctions.httpsCallable('removeContactsFromCampaign', { timeout: 540000 })({org: this.orgIdService.getOrgId(), campaignId: data.campaignId, contactIds: data.contactIds}).toPromise();
        return true;
      } catch (err) {
        console.log('An error occurred deleting the selected contacts from this conversation', err);
      }
      alert('An unknown error occurred deleting the selected contacts from this conversation');
    }),
    filter(() => false)
  ));


  loadConversationsForUser$ = createEffect(() => this.actions$.pipe(
    ofType<ActionWithPayload<{userId:string, campaignId:string}>>(ConversationActions.LOAD_CONVERSATIONS_FOR_USER),
    switchMap(action => {
      return this.db.object(`/organizations/${this.orgIdService.getOrgId()}/user-to-campaigns/${action.payload.userId}/${action.payload.campaignId}/conversations`)
        .snapshotChanges()
        .pipe(
          map( (newValue) => ({ userId: action.payload.userId, campaignId: action.payload.campaignId, snapshot: newValue }) )
        );
    }),
    catchError( (err, caught) => {
      console.warn('Error in ConversationEffects.loadConversationsForUser$', err, caught);
      return of(null);
    }),
    filter(v => !!v),
    switchMap(snap => {
      const obj = snap.snapshot.payload.val() || {};
      const actions = [];
      Object.keys(obj).forEach(contactId => {
        actions.push(this.conversationActions.load({
          campaignId: snap.campaignId,
          contactId: contactId
        }));
      });
      return actions;
    })
  ));
}

import { CampaignEffects } from './campaign.effects';
import { ContactEffects } from './contact.effects';
import { ConversationEffects } from './conversation.effects';
import { MessageEffects } from './message.effects';
import { UserEffects } from './user.effects';
import { UsersForCampaignEffects } from './users-for-campaign.effects';
import { SettingsEffects } from './settings.effects';
import { TagEffects } from './tag.effects';

export {
  CampaignEffects,
  ContactEffects,
  ConversationEffects,
  MessageEffects,
  SettingsEffects,
  TagEffects,
  UserEffects,
  UsersForCampaignEffects
};

export default [
  CampaignEffects,
  ContactEffects,
  ConversationEffects,
  MessageEffects,
  SettingsEffects,
  TagEffects,
  UserEffects,
  UsersForCampaignEffects
];

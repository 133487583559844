<section class="slice slice-navbar bg-gradient-primary" data-separator-bg="secondary"><div></div></section>

<section class="slice slice-lg">
  <div class="container pt-lg-md">
      <div class="row justify-content-center">
          <div class="col-lg-8">
              <div class="card shadow-lg">
                  <div class="card-body px-lg-5 py-lg-5">
                      <div class="text-center mb-4">
                          <h2 class="parlance h2 pt-3">parlance</h2>
                          <h4 class="heading h4 pt-2">Get your organization signed up</h4>
                          <p class="text-muted">(Looking to <a [routerLink]="['/login']">sign-in as a user</a>?)</p>
                      </div>
                      <span class="clearfix"></span>
                      <form role="form" [formGroup]="form">
                          <h5 class="heading h5">Organization Information</h5>
                          <div class="form-group mb-4">
                              <input type="text" class="form-control form-control-lg form-control-emphasized" placeholder="Organization name" formControlName="orgName">
                          </div>
                          <div class="form-group mb-4">
                              <input type="text" class="form-control form-control-lg form-control-emphasized" placeholder="Mailing address" formControlName="orgAddress">
                          </div>
                          <div class="row">
                              <div class="col-lg-6">
                                  <div class="form-group mb-4">
                                      <input class="form-control form-control-lg form-control-emphasized" type="text" placeholder="City" formControlName="orgCity">
                                  </div>
                              </div>
                              <div class="col-lg-2">
                                  <div class="form-group mb-4">
                                      <input class="form-control form-control-lg form-control-emphasized" type="text" placeholder="State" formControlName="orgState">
                                  </div>
                              </div>
                              <div class="col-lg-4">
                                  <div class="form-group mb-4">
                                      <input class="form-control form-control-lg form-control-emphasized" type="text" placeholder="Zip" formControlName="orgZip">
                                  </div>
                              </div>
                          </div>
                          <h5>Choose an organization id</h5>
                          <div>
                            This id should be a short, unique way to identify your organization in links to your campaigns and users.
                          </div>
                          <div class="row">
                              <div class="col-sm-4 text-right pt-3 pr-0">
                                https://parlance.app/go/
                              </div>
                              <div class="col-sm-6">
                                <div class="form-group mb-0">
                                  <input type="text" class="form-control form-control-lg form-control-emphasized" placeholder="Organization id" formControlName="orgId">
                                  <p class="help text-danger mb-0" *ngIf="orgId.invalid && (orgId.touched || orgId.dirty)">
                                    <span *ngIf="orgId.errors.required">Please enter a short id for your organization<br/></span>
                                    <span *ngIf="orgId.errors.minlength">The id must be at least 3 characters long<br/></span>
                                    <span *ngIf="orgId.errors.maxlength">The id must cannot be longer than 30 characters<br/></span>
                                    <span *ngIf="orgId.errors.pattern">Only letters, numbers, and dashes are allowed. No spaces or other characters.<br/></span>
                                  </p>
                                  <p class="help text-muted mb-0" *ngIf="orgId.status == 'PENDING'">
                                    <i class="fas fa-spinner fa-spin"></i> Checking availability
                                  </p>
                                  <p class="help text-success mb-0" *ngIf="orgId.valid">
                                    <i class="fas fa-check-circle"></i> Available!
                                  </p>
                                  <p class="help text-danger mb-0" *ngIf="orgId.invalid && (orgId.touched || orgId.dirty) && orgId.errors.uniqueOrgId">
                                    <i class="fas fa-times-circle"></i> Taken
                                  </p>
                                  <p class="help text-danger mb-0" *ngIf="orgId.invalid && (orgId.touched || orgId.dirty) && orgId.errors.networkError">
                                    <i class="fas fa-times-circle"></i> Network error. Try again.
                                  </p>
                                </div>
                              </div>
                          </div>
                          <p class="help text-muted">
                            Only letters, numbers, and dashes are allowed. No spaces or other characters.
                          </p>
                          <h5 class="heading h5 pt-2">Your Information</h5>
                          <div class="row">
                              <div class="col-lg-6">
                                  <div class="form-group mb-4">
                                      <input class="form-control form-control-lg form-control-emphasized" type="text" placeholder="First name" formControlName="firstName">
                                  </div>
                              </div>
                              <div class="col-lg-6">
                                  <div class="form-group mb-4">
                                      <input class="form-control form-control-lg form-control-emphasized" type="text" placeholder="Last name" formControlName="lastName">
                                  </div>
                              </div>
                          </div>
                          <div class="form-group mb-4">
                              <input type="text" class="form-control form-control-lg form-control-emphasized" placeholder="Title/Position" formControlName="title">
                          </div>
                          <div class="form-group mb-4">
                              <input type="email" class="form-control form-control-lg form-control-emphasized" placeholder="Your email" formControlName="email">
                          </div>
                          <div class="row">
                              <div class="col-lg-6">
                                  <div class="form-group mb-4">
                                      <input type="password" class="form-control form-control-lg form-control-emphasized" placeholder="Password" formControlName="password">
                                  </div>
                                  <div class="form-group mb-4">
                                      <input type="password" class="form-control form-control-lg form-control-emphasized" placeholder="Confirm password" formControlName="passwordConfirm">
                                  </div>
                              </div>
                              <div class="col-lg-6">
                                <p class="text-muted">
                                  <i *ngIf="!password.dirty && password.errors?.missingUpperCase" class="fas fa-times-circle text-muted"></i>
                                  <i *ngIf="password.dirty && password.errors?.missingUpperCase" class="fas fa-times-circle text-danger"></i>
                                  <i *ngIf="!password.errors?.missingUpperCase" class="fas fa-check-circle text-success"></i>
                                  Has uppercase letter<br/>

                                  <i *ngIf="!password.dirty && password.errors?.missingLowerCase" class="fas fa-times-circle text-muted"></i>
                                  <i *ngIf="password.dirty && password.errors?.missingLowerCase" class="fas fa-times-circle text-danger"></i>
                                  <i *ngIf="!password.errors?.missingLowerCase" class="fas fa-check-circle text-success"></i>
                                  Has lowercase letter<br/>

                                  <i *ngIf="!password.dirty && password.errors?.missingNumber" class="fas fa-times-circle text-muted"></i>
                                  <i *ngIf="password.dirty && password.errors?.missingNumber" class="fas fa-times-circle text-danger"></i>
                                  <i *ngIf="!password.errors?.missingNumber" class="fas fa-check-circle text-success"></i>
                                  Has a number<br/>

                                  <i *ngIf="!password.dirty && password.errors?.missingSymbol" class="fas fa-times-circle text-muted"></i>
                                  <i *ngIf="password.dirty && password.errors?.missingSymbol" class="fas fa-times-circle text-danger"></i>
                                  <i *ngIf="!password.errors?.missingSymbol" class="fas fa-check-circle text-success"></i>
                                  Has a sybmol (!@#$%^&amp;*)<br/>

                                  <i *ngIf="!password.dirty && password.errors?.tooShort" class="fas fa-times-circle text-muted"></i>
                                  <i *ngIf="password.dirty && password.errors?.tooShort" class="fas fa-times-circle text-danger"></i>
                                  <i *ngIf="!password.errors?.tooShort" class="fas fa-check-circle text-success"></i>
                                  At least 6 characters long<br/>

                                  <i *ngIf="!password.dirty && !confirmPassword.dirty" class="fas fa-times-circle text-muted"></i>
                                  <i *ngIf="(password.dirty || confirmPassword.dirty) && password.errors?.notEqual" class="fas fa-times-circle text-danger"></i>
                                  <i *ngIf="(password.dirty || confirmPassword.dirty) && !password.errors?.notEqual" class="fas fa-check-circle text-success"></i>
                                  Passwords match<br/>
                                </p>
                              </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-6">
                              <div class="form-group mb-0">
                                  <input type="tel" class="form-control form-control-lg form-control-emphasized" placeholder="Cell phone number" formControlName="phone">
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-lg-12">
                              <p class="help text-muted">This number should be capable of receiving text messages</p>
                            </div>
                          </div>


                          <div class="row">
                            <div class="col-lg-6">
                              <div class="form-group mb-0">
                                  <input type="tel" class="form-control form-control-lg form-control-emphasized" placeholder="Local area code" formControlName="areaCode">
                              </div>
                            </div>
                            <div class="col-lg-6">
                              <div class="form-group mb-0">
                                <input class="form-check-input" type="checkbox" id="giftNet" formControlName="giftNet">
                                <label class="form-check-label" for="giftNet">
                                  Enable GiftNet
                                </label>
                              </div>
                            </div>
                          </div>

                          <div class="row mb-4">
                              <div class="col-12">
                                  <p class="text-muted">By creating an account you agree to our Terms and Conditions and our Privacy Policy.</p>
                              </div>
                          </div>
                          <button type="submit" class="btn btn-primary" (click)="doSignUp()" [disabled]="!form.valid || processing">
                            <span *ngIf="!processing">Create account</span>
                            <span *ngIf="processing"><i class="fas fa-spinner fa-spin"></i> Please wait</span>
                          </button>
                      </form>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>

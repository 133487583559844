import { EntityState } from '@ngrx/entity';
import { ContactInfo } from './contact-info';
import { User as firebaseUser } from 'firebase/auth';

export enum Roles {
  Owner = "Owner",
  Admin = "Admin",
  User = "User",
  None = "None"
}

export enum Status {
  Pending = "Pending",
  Invited = "Invited",
  Active = "Active",
  Locked = "Locked"
}

export class User {
  id?: string;
  displayName?: string;
  contactInfo: ContactInfo;
  authState?: firebaseUser;
  settings?: any;
  role: Roles = Roles.None;
  lastSeenOn: number;
  organizations: any;
  forceChangePassword: boolean;
  status: Status;
  disabled?: boolean;
  userType?: string;
  year?: string;
  link?: string;
  code?: string;

  constructor(json?:any) {
    if (json) {
      this.id = json.id;
      this.displayName = json.displayName || '';
      this.contactInfo = new ContactInfo(json.contactInfo);
      this.authState = json.authState || '';
      this.settings = json.settings || ''
      this.role = json.role || Roles.None;
      this.lastSeenOn = json.lastSeenOn || 0;
      this.organizations = json.organizations || {};
      this.forceChangePassword = json.forceChangePassword || false;
      this.status = json.status || Status.Pending;
      this.disabled = json.disabled || false;
      this.userType = json.userType || '';
      this.year = json.year || '';
      this.link = json.link || '';
      this.code = json.code || '';
    } else {
      this.settings = {};
      this.role = Roles.None;
      this.lastSeenOn = 0;
      this.organizations = {};
      this.forceChangePassword = false;
      this.status = Status.Pending;
      this.disabled = false;
      this.userType = '';
      this.year = '';
      this.link = '';
      this.code = '';
    }
  }
}

export interface UserState extends EntityState<User> { }

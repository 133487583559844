import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { ActionWithPayload } from './action-with-payload';

import { UsersForCampaign } from '../../model';

@Injectable()
export class UsersForCampaignActions {
  static LOAD_FOR_CAMPAIGN = '[UsersForCampaign] Load for Campaign';
  loadForCampaign(campaignId: string): ActionWithPayload<string> {
    return {
      type: UsersForCampaignActions.LOAD_FOR_CAMPAIGN,
      payload: campaignId
    };
  }

  static LOAD_FOR_CAMPAIGN_SUCCESS = '[UsersForCampaign] Load for Campaign Success';
  loadForCampaignSuccess(value): ActionWithPayload<any> {
    return {
      type: UsersForCampaignActions.LOAD_FOR_CAMPAIGN_SUCCESS,
      payload: value
    }
  }

  static UPDATE = '[UsersForCampaign] Update';
  update(campaignId: string, userIds: any): ActionWithPayload<{campaignId:string, userIds: any}> {
    return {
      type: UsersForCampaignActions.UPDATE,
      payload: { campaignId: campaignId, userIds: userIds}
    }
  }

  static ADD = '[UsersForCampaign] Add to Campaign';
  addToCampaign(data: {campaignId: string, userIds: any}): ActionWithPayload<{campaignId:string, userIds: any}> {
    return {
      type: UsersForCampaignActions.ADD,
      payload: data
    }
  }


  static REMOVE = '[UsersForCampaign] Remove from Campaign';
  removeFromCampaign(data: {campaignId: string, userIds: any}): ActionWithPayload<{campaignId:string, userIds: any}> {
    return {
      type: UsersForCampaignActions.REMOVE,
      payload: data
    }
  }

}

import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { ActionWithPayload } from './action-with-payload';

import { Conversation, ConversationStatus, Family, ConversationEvent, User } from '../../model';

@Injectable()
export class ConversationActions {
  static LOAD_SUMMARIES_FOR_CAMPAIGN = '[Conversations] Load for Campaign';
  loadSummariesForCampaign(campaignId: string): ActionWithPayload<string> {
    return {
      type: ConversationActions.LOAD_SUMMARIES_FOR_CAMPAIGN,
      payload: campaignId
    };
  }

  static LOAD_SUMMARIES_FOR_CAMPAIGN_SUCCESS = '[Conversations] Load for Campaign Success';
  loadForCampaignSuccess(value): ActionWithPayload<any> {
    return {
      type: ConversationActions.LOAD_SUMMARIES_FOR_CAMPAIGN_SUCCESS,
      payload: value
    };
  }

  static LOAD_CONVERSATIONS_FOR_USER = '[Conversations] Load for user and campaign';
  loadForUserCampaign(data:{userId:string, campaignId:string}): ActionWithPayload<{userId:string, campaignId:string}> {
    return {
      type: ConversationActions.LOAD_CONVERSATIONS_FOR_USER,
      payload: data
    };
  }

  static LOAD_CONVERSATIONS_FOR_USER_SUCCESS = '[Conversations] Load for user and campaign success';
  loadForUserCampaignSuccess(data:{userId:string, campaignId:string, conversations:any[]}): ActionWithPayload<{userId:string, campaignId:string, conversations:any[]}> {
    return {
      type: ConversationActions.LOAD_CONVERSATIONS_FOR_USER_SUCCESS,
      payload: data
    };
  }

  static LOAD = '[Conversations] Load specific conversation';
  load(data:{ campaignId: string, contactId: string }): ActionWithPayload<{ campaignId:string, contactId:string }> {
    return {
      type: ConversationActions.LOAD,
      payload: data
    };
  }

  static LOAD_SUCCESS = '[Conversations] Loaded specific conversation';
  loadSuccess(value): ActionWithPayload<Conversation> {
    return {
      type: ConversationActions.LOAD_SUCCESS,
      payload: value
    };
  }

  static LOAD_BATCH = '[Conversations] Batch Load specific conversation';
  loadBatch(data:{campaignId: string, contactId: string}[]): ActionWithPayload<{campaignId:string, contactId:string}[]> {
    return {
      type: ConversationActions.LOAD_BATCH,
      payload: data
    };
  }

  static LOAD_SUCCESS_BATCH = '[Conversations] Batch Loaded specific conversation';
  loadSuccessBatch(value): ActionWithPayload<[Conversation]> {
    return {
      type: ConversationActions.LOAD_SUCCESS_BATCH,
      payload: value
    };
  }

  static START_TEXT_PRIMARY = '[Conversation] Start Text Conversations with Primary Contacts';
  startTextConversationsWithPrimaryContact(data: {campaignId:string, contact: Family, userId: string}[]): ActionWithPayload<{campaignId:string, contact: Family, userId: string}[]> {
    return {
      type: ConversationActions.START_TEXT_PRIMARY,
      payload: data
    };
  }

  static LOAD_UNREADS = '[Conversation] Load Unreads';
  loadUnreads(data: {campaignId:string}): ActionWithPayload<{campaignId:string}> {
    return {
      type: ConversationActions.LOAD_UNREADS,
      payload: data
    };
  }

  static LOAD_UNREADS_SUCCESS = '[Conversations] Loaded Unreads Success';
  loadUnreadsSuccess(value:{userId:string, value:string}): ActionWithPayload<{userId:string, value:string}> {
    return {
      type: ConversationActions.LOAD_UNREADS_SUCCESS,
      payload: value
    };
  }

  static LOAD_UNREAD = '[Conversation] Load Single Unread';
  loadUnread(data: {campaignId:string, contactId:string}): ActionWithPayload<{campaignId:string, contactId:string}> {
    return {
      type: ConversationActions.LOAD_UNREAD,
      payload: data
    };
  }

  static LOAD_UNREAD_SUCCESS = '[Conversations] Loaded Single Unread Success';
  loadUnreadSuccess(value:{campaignId:string, contactId:string, unread:boolean}): ActionWithPayload<{campaignId:string, contactId:string, unread:boolean}> {
    return {
      type: ConversationActions.LOAD_UNREAD_SUCCESS,
      payload: value
    };
  }

  static DELETE_CONVERSATIONS = '[Conversation] Delete conversations';
  deleteConversations(data: {campaignId: string, contactIds:any}): ActionWithPayload<{campaignId: string, contactIds:any}> {
    return {
      type: ConversationActions.DELETE_CONVERSATIONS,
      payload: data,
    };
  }

  static RECORD_EVENT = '[Conversation] Record event';
  recordEvent(data: {campaignId: string, contactId: string, event:ConversationEvent, data?: object, user?: User}): ActionWithPayload<{campaignId: string, contactId: string, event: ConversationEvent, data?: object, user?: User}> {
    return {
      type: ConversationActions.RECORD_EVENT,
      payload: { ...data, ...{ event: data.event, data: data.data, user: data.user } }
    };
  }

  static ADD_NOTE = '[Conversation] Add note';
  addNote(data: {campaignId: string, contactId: string, note: string, user?: any}): ActionWithPayload<{campaignId: string, contactId: string, note: string, user?: any}> {
    return {
      type: ConversationActions.ADD_NOTE,
      payload: data,
    };
  }

  static UPDATE_LOCAL_CONTACT = '[Conversation] Update local contact';
  updateLocalContact(data: {campaignId: string, contactId: string, family: Family}): ActionWithPayload<{campaignId: string, contactId: string, family: Family}> {
    return {
      type: ConversationActions.UPDATE_LOCAL_CONTACT,
      payload: { ...data }
    };
  }

  static RECORD_ATTEMPT = '[Conversation] Record attempt';
  recordAttempt(data: {campaignId: string, contactId: string, attempt: any}): ActionWithPayload<{campaignId: string, contactId: string, attempt: any}> {
    return {
      type: ConversationActions.RECORD_ATTEMPT,
      payload: { ...data }
    };
  }

  static SET_NEXT_ATTEMPT_AFTER = '[Conversation] Set next attempt after';
  setNextAttemptAfter(data: {campaignId: string, contactId: string, after: number}): ActionWithPayload<{campaignId: string, contactId: string, after: number}> {
    return {
      type: ConversationActions.SET_NEXT_ATTEMPT_AFTER,
      payload: { ...data }
    };
  }

  static UPDATE_FORMS= '[Conversation] Update forms';
  updateForms(data: {campaignId: string, contactId: string, forms: object}): ActionWithPayload<{campaignId: string, contactId: string, forms: object}> {
    return {
      type: ConversationActions.UPDATE_FORMS,
      payload: { ...data }
    };
  }

  static UPDATE_SPECIFIC_FORM_FIELD= '[Conversation] Update specific form field';
  updateSpecificFormField(data: {campaignId: string, contactId: string, formSection: number, formQuestion: number, formValue: string }):
    ActionWithPayload<{campaignId: string, contactId: string, formSection: number, formQuestion: number, formValue: string }> {
    return {
      type: ConversationActions.UPDATE_SPECIFIC_FORM_FIELD,
      payload: { ...data }
    };
  }

  static UPDATE_LAST_SENT= '[Conversation] Update last sent';
  updateLastSentAt(data: {campaignId: string, contactId: string, lastMessageSentAt: number}): ActionWithPayload<{campaignId: string, contactId: string, lastMessageSentAt: number}> {
    return {
      type: ConversationActions.UPDATE_LAST_SENT,
      payload: { ...data }
    };
  }

  static UPDATE_STATUS= '[Conversation] Update status';
  setStatus(data: {campaignId: string, contactId: string, status: ConversationStatus}): ActionWithPayload<{campaignId: string, contactId: string, successData?: object, status: ConversationStatus}> {
    return {
      type: ConversationActions.UPDATE_STATUS,
      payload: { ...data }
    };
  }

  markSuccess(data: {campaignId: string, contactId: string, successData?: object}): ActionWithPayload<{campaignId: string, contactId: string, successData?: object, status: ConversationStatus}> {
    return {
      type: ConversationActions.UPDATE_STATUS,
      payload: { ...data, ...{ status: ConversationStatus.Success } }
    };
  }

  markMaybe(data: {campaignId: string, contactId: string}): ActionWithPayload<{campaignId: string, contactId: string, successData?: object, status: ConversationStatus}> {
    return {
      type: ConversationActions.UPDATE_STATUS,
      payload: { ...data, ...{ status: ConversationStatus.Maybe, successData: {} } }
    };
  }

  markPending(data: {campaignId: string, contactId: string}): ActionWithPayload<{campaignId: string, contactId: string, successData?: object, status: ConversationStatus}> {
    return {
      type: ConversationActions.UPDATE_STATUS,
      payload: { ...data, ...{ status: ConversationStatus.Pending, successData: {} } }
    };
  }

  markStarted(data: {campaignId: string, contactId: string}): ActionWithPayload<{campaignId: string, contactId: string, successData?: object, status: ConversationStatus}> {
    return {
      type: ConversationActions.UPDATE_STATUS,
      payload: { ...data, ...{ status: ConversationStatus.Started, successData: {} } }
    };
  }

  markGave(data: {campaignId: string, contactId: string}): ActionWithPayload<{campaignId: string, contactId: string, successData?: object, status: ConversationStatus}> {
    return {
      type: ConversationActions.UPDATE_STATUS,
      payload: { ...data, ...{ status: ConversationStatus.Gave, successData: {} } }
    };
  }

  markDeclined(data: {campaignId: string, contactId: string}): ActionWithPayload<{campaignId: string, contactId: string, successData?: object, status: ConversationStatus}> {
    return {
      type: ConversationActions.UPDATE_STATUS,
      payload: { ...data, ...{ status: ConversationStatus.Declined, successData: {} } }
    };
  }

  markDeclinedOther(data: {campaignId: string, contactId: string}): ActionWithPayload<{campaignId: string, contactId: string, successData?: object, status: ConversationStatus}> {
    return {
      type: ConversationActions.UPDATE_STATUS,
      payload: { ...data, ...{ status: ConversationStatus.DeclinedOther, successData: {} } }
    };
  }

  markWrongNumber(data: {campaignId: string, contactId: string}): ActionWithPayload<{campaignId: string, contactId: string, successData?: object, status: ConversationStatus}> {
    return {
      type: ConversationActions.UPDATE_STATUS,
      payload: { ...data, ...{ status: ConversationStatus.WrongNumber, successData: {} } }
    };
  }

  markOptOut(data: {campaignId: string, contactId: string}): ActionWithPayload<{campaignId: string, contactId: string, successData?: object, status: ConversationStatus}> {
    return {
      type: ConversationActions.UPDATE_STATUS,
      payload: { ...data, ...{ status: ConversationStatus.OptOut, successData: {} } }
    };
  }

  static MARK_READ= '[Conversation] Mark Read';
  markRead(data: {campaignId: string, contactId: string}): ActionWithPayload<{campaignId: string, contactId: string}> {
    return {
      type: ConversationActions.MARK_READ,
      payload: data
    };
  }

  static SET_VIEWING = '[Conversation] Set viewing';
  setViewing(data: {campaignId: string, contactId: string, user: User}): ActionWithPayload<{campaignId: string, contactId: string, user: User}> {
    return {
      type: ConversationActions.SET_VIEWING,
      payload: data
    };
  }

  static CLEAR_VIEWING = '[Conversation] Clear viewing';
  clearViewing(data: {campaignId: string, contactId: string, user: User}): ActionWithPayload<{campaignId: string, contactId: string, user: User}> {
    return {
      type: ConversationActions.CLEAR_VIEWING,
      payload: data
    };
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CallInProgressGuard {

  constructor(
    private router: Router,
  ) { }

  canDeactivate(component: HasCallInProgress): boolean {
    if (component.isCallInProgress && component.isCallInProgress()) {
      return confirm('You are currently on a call. Navigating to a different page will cause the call to disconnect. Are you sure you want to leave this page?');
    }
    return true;
  }
}

export interface HasCallInProgress {
  isCallInProgress(): boolean;
}

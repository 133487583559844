<section class="slice slice-navbar bg-gradient-primary" data-separator-bg="secondary"><div></div></section>

<section class="slice slice-lg">
  <div class="container">
      <div class="mb-md text-center">
          <h3 class="heading h2">Flexible &amp; Affordable Pricing</h3>
          <div class="fluid-paragraph mt-3">
              <p class="lead">No matter what your messaging needs are, Parlance has an affordable pricing option for you.</p>
          </div>
      </div>
      <div class="row row-grid">
          <div class="col-lg-6">
              <div class="px-4 text-center">
                  <h3 class="heading h3 mb-3">Texting</h3>
                  <h4 class="heading h4 mb-4 text-primary">
                      $0.20/contact
                  </h4>
                  <div>Unlimited messages for 30 days</div>
              </div>
          </div>
          <div class="col-lg-6">
              <div class="px-4 text-center">
                <h3 class="heading h3 mb-3">Texting & Phoning</h3>
                <h4 class="heading h4 mb-4 text-primary">
                    $0.35/contact
                </h4>
                <div>Unlimited messages &amp; calls for 30 days</div>
              </div>
          </div>
          <div class="col-lg-4" *ngIf="false">
              <div class="px-4 text-center">
                <h3 class="heading h3 mb-3">Blasts</h3>
                <h4 class="heading h4 mb-4 text-primary">
                    $0.06/outgoing text
                </h4>
                <div>No charge on incoming messages</div>
                <div>Best for mass announcements, but still engage in conversations</div>
              </div>
          </div>
      </div>

      <div class="row row-grid">
        <div class="offset-lg-3 col-lg-6">
          <div class="px-4 text-center">
            <h3 class="heading h3 mb-3">All Plans Include</h3>
            <div>No commitments</div>
            <div>No monthly minimums</div>
            <div>Only pay for active contacts</div>
            <div>Free cell phone number validation</div>
            <div>Free updates to your contact's phone number</div>
            <div>Free technical &amp; strategic support</div>
            <div>Free support for families (each contact in a family is billed individually)</div>
            <div>A nominal setup fee</div>
          </div>
        </div>
      </div>

      <div class="row justify-content-center align-items-center">
        <div class="mt-4">
          <a target="_blank" href="https://calendly.com/parlanceapp/parlance-introductory-call" class="btn btn-primary btn-circle btn-translate--hover px-4">Get a Demo</a>
          <a [routerLink]="['/']" class="btn btn-link">Learn More</a>
        </div>
      </div>

  </div>
</section>

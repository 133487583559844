import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { Router } from '@angular/router';

import { RouterModule, RouteReuseStrategy } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ChartsModule } from 'ng2-charts';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireFunctionsModule } from '@angular/fire/compat/functions';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import * as AppActions from './store/actions';
import * as AppEffects from './store/effects';
import { reducers } from './store/app.store';
import { AuthenticationService, AuthGuardService, AuthPhoneNumberService, CampaignService, ContactService, UserService } from './services';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { LoginPage } from './pages/login/login.page';
import { ChangePasswordPage } from './pages/change-password/change-password.page';
import { MainPage } from './pages/brochure/main/main.page';
import { OverviewComponent } from './pages/brochure/overview/overview.component';
import { PricingComponent } from './pages/brochure/pricing/pricing.component';
import { HowItWorksComponent } from './pages/brochure/how-it-works/how-it-works.component';
import { OrgSignUpComponent } from './pages/brochure/org-sign-up/org-sign-up.component';
import { UniqueOrgIdValidator } from './pages/brochure/org-sign-up/unique-org-id-validator';
import { GetStartedComponent } from './pages/brochure/get-started/get-started.component';
import { PrivacyPolicyComponent } from './pages/brochure/privacy-policy/privacy-policy.component';
import { TermsOfServiceComponent } from './pages/brochure/terms-of-service/terms-of-service.component';

export const firebaseConfig = {
    apiKey: 'AIzaSyBKWDo9QWuX4lFsAM9CyPN4lCVThjw7dEs',
    authDomain: 'parlanceexploratory.firebaseapp.com',
    databaseURL: 'https://parlanceexploratory.firebaseio.com',
    storageBucket: 'parlanceexploratory.appspot.com',
    messagingSenderId: '216873173901',
    projectId: 'parlanceexploratory'
};

@NgModule({
  declarations: [
    AppComponent,
    ChangePasswordPage,
    LoginPage,
    MainPage,
    OverviewComponent,
    HowItWorksComponent,
    OrgSignUpComponent,
    PricingComponent,
    GetStartedComponent,
    PrivacyPolicyComponent,
    TermsOfServiceComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,

    // ng-bootstrap,
    NgbModule,

    // ng2-charts
    ChartsModule,

    // Firebase
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireFunctionsModule,

    // Store
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    StoreDevtoolsModule.instrument({maxAge:20}),

    // ngrx Effects
    EffectsModule.forRoot([
      AppEffects.CampaignEffects,
      AppEffects.ContactEffects,
      AppEffects.ConversationEffects,
      AppEffects.MessageEffects,
      AppEffects.SettingsEffects,
      AppEffects.TagEffects,
      AppEffects.UserEffects,
      AppEffects.UsersForCampaignEffects
    ]),
  ],
  providers: [
    AuthenticationService,
    AuthGuardService,
    AppActions.CampaignActions,
    AppActions.ContactActions,
    AppActions.ConversationActions,
    AppActions.MessageActions,
    AppActions.SettingsActions,
    AppActions.TagActions,
    AppActions.UserActions,
    AppActions.UsersForCampaignActions,
    CampaignService,
    ContactService,
    UserService,
    UniqueOrgIdValidator,
    AuthPhoneNumberService,
  ],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule { }

<section class="slice slice-lg pb-lg-0 bg-gradient-primary" data-separator="rounded-left" data-separator-orientation="bottom">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8">
                <div class="mb--100 position-relative" style="z-index: 1;">
                    <div class="pt-lg-lg text-center">
                        <h6 class="text-white text-uppercase ls-2 mb-4 text-muted font-weight-700">Get started with parlance</h6>
                        <h2 class="heading h2 text-white lh-160 font-weight-300">No matter you want to build a small business website or a complex one, this theme offers you great tools and features in order to do that.</h2>
                    </div>
                    <div class="list-group list-group-space mt-5">
                        <a href="#" class="list-group-item list-group-item-action d-flex flex-column flex-md-row align-items-center px-sm text-center text-md-left box-shadow-2" data-toggle="tooltip" data-original-title="Start with this">
                            <div class="mr-md-sm mb-4 mb-md-0">
                                <img src="assets/main/img/icons/small-business.png" class="img-fluid">
                            </div>
                            <div class="list-group-content">
                                <div class="h5">I want to build a bussiness website</div>
                                <p class="text-sm">parlance is a great free UI package based on Bootstrap 4 that includes the most important components and features for modern looking websites.</p>
                            </div>
                        </a>
                        <a href="#" class="list-group-item list-group-item-action d-flex flex-column flex-md-row align-items-center px-sm text-center text-md-left box-shadow-2" data-toggle="tooltip" data-original-title="Start with this">
                            <div class="mr-md-sm mb-4 mb-md-0">
                                <img src="../assets/img/icons/dusk/png/template.png" class="img-fluid">
                            </div>
                            <div class="list-group-content">
                                <div class="h5">I want to showcase my work</div>
                                <p class="text-sm">parlance is a great free UI package based on Bootstrap 4 that includes the most important components and features for modern looking websites.</p>
                            </div>
                        </a>
                        <a href="#" class="list-group-item list-group-item-action d-flex flex-column flex-md-row align-items-center px-sm text-center text-md-left box-shadow-2" data-toggle="tooltip" data-original-title="Start with this">
                            <div class="mr-md-sm mb-4 mb-md-0">
                                <img src="../assets/img/icons/dusk/png/card-payment.png" class="img-fluid">
                            </div>
                            <div class="list-group-content">
                                <div class="h5">I want to create an online store</div>
                                <p class="text-sm">parlance is a great free UI package based on Bootstrap 4 that includes the most important components and features for modern looking websites.</p>
                            </div>
                        </a>
                        <a href="#" class="list-group-item list-group-item-action d-flex flex-column flex-md-row align-items-center px-sm text-center text-md-left box-shadow-2" data-toggle="tooltip" data-original-title="Start with this">
                            <div class="mr-md-sm mb-4 mb-md-0">
                                <img src="../assets/img/icons/dusk/png/administrative-tools.png" class="img-fluid">
                            </div>
                            <div class="list-group-content">
                                <div class="h5">I want to build a web app</div>
                                <p class="text-sm">parlance is a great free UI package based on Bootstrap 4 that includes the most important components and features for modern looking websites.</p>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="slice slice-lg pt-200">
  <div class="container">
      <div class="mb-md text-center">
          <h3 class="heading h2">We help businesses grow</h3>
          <div class="fluid-paragraph mt-3">
              <p class="lead">Start building fast, beautiful and modern looking websites in no time using our theme.</p>
          </div>
      </div>
      <div class="row row-grid">
          <div class="col-lg-4">
              <div class="px-4 text-center">
                  <div class="mb-4">
                      <div class="icon icon-lg icon-shape box-shadow-3 rounded-circle bg-primary text-white">
                          <svg class="svg-inline--fa fa-paint-brush fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="paint-brush" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M269.9 364.6c1.4 6.4 2.1 13 2.1 19.7 0 81.2-54.2 127.7-134.8 127.7C41.5 512 0 435.1 0 347.6c10.4 7.1 46.9 36.5 58.7 36.5 7 0 13-4 15.5-10.6 23.6-62.2 66.5-76.5 112.9-77.4 15.6 33.8 46.1 59.6 82.8 68.5zM460.6 0c-14.4 0-27.9 6.4-38.2 15.7C228.2 190 208 194.1 208 245.4c0 48.8 40.5 90.6 90.2 90.6 59 0 93.2-43.4 200.6-244.8 7-13.7 13.2-28.5 13.2-43.9C512 19.7 487.3 0 460.6 0z"></path></svg><!-- <i class="fas fa-paint-brush"></i> -->
                      </div>
                  </div>
                  <h5 class="heading h5 mb-3">20+ Landing pages</h5>
                  <p>With parlance you get components and examples, including tons of variables that will help you customize this theme with ease.</p>
                  <a href="#" class="btn btn-sm btn-outline-primary btn-circle mt-3">Learn more</a>
              </div>
          </div>
          <div class="col-lg-4">
              <div class="px-4 text-center">
                  <div class="mb-4">
                      <div class="icon icon-lg icon-shape box-shadow-3 rounded-circle bg-primary text-white">
                          <svg class="svg-inline--fa fa-code fa-w-20" aria-hidden="true" data-prefix="fas" data-icon="code" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" data-fa-i2svg=""><path fill="currentColor" d="M278.9 511.5l-61-17.7c-6.4-1.8-10-8.5-8.2-14.9L346.2 8.7c1.8-6.4 8.5-10 14.9-8.2l61 17.7c6.4 1.8 10 8.5 8.2 14.9L293.8 503.3c-1.9 6.4-8.5 10.1-14.9 8.2zm-114-112.2l43.5-46.4c4.6-4.9 4.3-12.7-.8-17.2L117 256l90.6-79.7c5.1-4.5 5.5-12.3.8-17.2l-43.5-46.4c-4.5-4.8-12.1-5.1-17-.5L3.8 247.2c-5.1 4.7-5.1 12.8 0 17.5l144.1 135.1c4.9 4.6 12.5 4.4 17-.5zm327.2.6l144.1-135.1c5.1-4.7 5.1-12.8 0-17.5L492.1 112.1c-4.8-4.5-12.4-4.3-17 .5L431.6 159c-4.6 4.9-4.3 12.7.8 17.2L523 256l-90.6 79.7c-5.1 4.5-5.5 12.3-.8 17.2l43.5 46.4c4.5 4.9 12.1 5.1 17 .6z"></path></svg><!-- <i class="fas fa-code"></i> -->
                      </div>
                  </div>
                  <h5 class="heading h5 mb-3">Really useful features</h5>
                  <p>With parlance you get components and examples, including tons of variables that will help you customize this theme with ease.</p>
                  <a href="#" class="btn btn-sm btn-outline-primary btn-circle mt-3">Learn more</a>
              </div>
          </div>
          <div class="col-lg-4">
              <div class="px-4 text-center">
                  <div class="mb-4">
                      <div class="icon icon-lg icon-shape box-shadow-3 rounded-circle bg-primary text-white">
                          <svg class="svg-inline--fa fa-tint fa-w-12" aria-hidden="true" data-prefix="fas" data-icon="tint" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" data-fa-i2svg=""><path fill="currentColor" d="M192 512c-98.435 0-178.087-79.652-178.087-178.087 0-111.196 101.194-154.065 148.522-311.825 9.104-30.116 51.099-28.778 59.13 0 47.546 158.486 148.522 200.069 148.522 311.825C370.087 432.348 290.435 512 192 512zm-42.522-171.826c-1.509-5.533-9.447-5.532-10.956 0-9.223 29.425-27.913 37.645-27.913 58.435C110.609 417.13 125.478 432 144 432s33.391-14.87 33.391-33.391c0-20.839-18.673-28.956-27.913-58.435z"></path></svg><!-- <i class="fas fa-tint"></i> -->
                      </div>
                  </div>
                  <h5 class="heading h5 mb-3">Highly customizable</h5>
                  <p>With parlance you get components and examples, including tons of variables that will help you customize this theme with ease.</p>
                  <a href="#" class="btn btn-sm btn-outline-primary btn-circle mt-3">Learn more</a>
              </div>
          </div>
      </div>
  </div>
</section>

import { Component } from '@angular/core';

import { Store } from '@ngrx/store';
import { AppStore } from './store/app.store';
import { AuthenticationService } from './services';
import { UserActions } from './store/actions';
import { RoutingState } from './app-routing.module';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Parlance';
  user;

  constructor(
    private store: Store<AppStore>,
    private auth: AuthenticationService,
    private userActions: UserActions,
    routingState: RoutingState
  ) {
    this.user = auth.authInfo;
    routingState.loadRouting();
    this.initializeApp();
  }

  initializeApp() {
  }

  logout() {
    this.auth.logout();
  }
}

import { Observable } from 'rxjs';
import { Action } from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createSelector, createFeatureSelector } from '@ngrx/store';

import { ActionWithPayload, UserActions } from '../actions';
import { User, UserState } from '../../model';

// Entity adapter
export const userAdapter = createEntityAdapter<User>();
export const initialState: UserState = userAdapter.getInitialState();

export function user(state: any = undefined, action: ActionWithPayload<User>):User {
  switch(action.type) {
    case UserActions.UPDATE_LOGGED_IN_USER:
      return action.payload;
    case UserActions.LOGIN_SUCCESS:
      return action.payload;
    case UserActions.LOGOFF:
      return null;
    default:
      return state;
  }
};

export function userReducer(state: UserState = initialState, action: ActionWithPayload<any>) {
  switch(action.type) {
    case UserActions.LOAD_ALL_SUCCESS:
      return userAdapter.setAll(action.payload, state);
    case UserActions.ADDED:
      return userAdapter.addOne(action.payload, state);
    case UserActions.MODIFIED:
      return userAdapter.updateOne({id: action.payload.id, changes: action.payload.changes}, state);
    case UserActions.REMOVED:
      return userAdapter.removeOne(action.payload.id, state);
    case UserActions.SAVE_SUCCESS:
      console.log('USER SAVE SUCCESS', action.payload, state);
      return { ...state, [action.payload.id]: action.payload.user };
    default:
      return state;
  }
}
// export const users = (state: any = [], action: ActionWithPayload<User[]>):{[key: string]: User} => {
//   console.log(`Reducing for ${action.type}`);
//   switch(action.type) {
//     case UserActions.LOAD_LIST_SUCCESS:
//       console.log("New state post load", state, action.payload);
//       return action.payload;
//     default:
//       return state;
//   }
// };

export function userDictionary(state:any = {}, action: ActionWithPayload<User[]>):{[key: string]: User} {
  switch(action.type) {
    case UserActions.LOAD_ALL_SUCCESS:
      let users: User[] = action.payload;
      let userDict: {[key: string]: User} = {};
      users.forEach(user => {
        userDict[user.id] = user;
      });
      return userDict;
    default:
      return state;
  }
};

export const selectUserState = createFeatureSelector<UserState>('users');

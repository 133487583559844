import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Observable, of } from 'rxjs';
import { catchError, switchMap, exhaustMap, map, mergeMap, tap, filter } from 'rxjs/operators';

import { ActionWithPayload, SettingsActions } from '../actions';
import { OrgIdService } from '../../services';
import { Settings } from '../../model';

import { AngularFireDatabase } from '@angular/fire/compat/database';

@Injectable()
export class SettingsEffects {
  constructor(
    private actions$: Actions,
    private actions: SettingsActions,
    private db: AngularFireDatabase,
    private orgIdService: OrgIdService
  ) {}

  
  loadSettings$ = createEffect(() => this.actions$.pipe(
    ofType(SettingsActions.LOAD),
    switchMap(action => {
      return this.db.object(`/organizations/${this.orgIdService.getOrgId()}/settings`).snapshotChanges()
    }),
    catchError( (err, caught) => {
      console.warn('Error in SettingsEffects.loadSettings$', err, caught);
      return of(null);
    }),
    filter(v => !!v),
    map(action => {
      const obj = action.payload.val();
      return {
        type: `[Settings] ${action.type}`,
        payload: obj
      }
    })
  ));

  
  saveSettings$ = createEffect(() => this.actions$.pipe(
    ofType<ActionWithPayload<Settings>>(SettingsActions.SAVE),
    tap((action) => {
      const settings = action.payload;
      this.db.object(`/organizations/${this.orgIdService.getOrgId()}/settings`).update(settings).then(
        (retVal) => {
          // console.log('Update success', retVal);
        }, (error: Error) => {
          console.log('Error pushing', error);
        }
      );
    }),
    filter(() => false)
  ));
}

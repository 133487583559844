import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { AuthenticationService } from '../../services';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss']
})
export class LoginPage implements OnInit {
  user: object;
  form: UntypedFormGroup;
  resetForm: UntypedFormGroup;
  processing: boolean;
  forgotPassword: boolean;
  passwordReset: boolean;
  errorMessage: string;
  showPassword: boolean;

  constructor(
    private auth: AuthenticationService,
    private fb: UntypedFormBuilder
  ) {
    this.user = auth.authInfo;
    this.processing = false;
  }

  ngOnInit() {
    this.errorMessage = '';
    this.forgotPassword = false;
    this.passwordReset = false;
    this.showPassword = false;
    this.form = this.fb.group({
      email: this.fb.control('', [Validators.required, Validators.email]),
      password: this.fb.control('', [Validators.required, Validators.minLength(6)])
    });

    this.resetForm = this.fb.group({
      email: this.fb.control('', [Validators.required, Validators.email])
    });
  }

  showForgotPassword(show:boolean) {
    this.forgotPassword = show;
    this.passwordReset = false;
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

  resetPassword() {
    this.auth.resetPassword(this.resetForm.value.email)
      .then(() => this.passwordReset = true)
      .catch((err) => {
        alert('An error occurred while attempting to reset your password. Please try again.');
        this.errorMessage = err.message;
        console.log(err);
      });
  }

  loginViaEmailPassword() {
    console.log('Logging in with email & password');
    if (this.form.invalid) {
      console.log('Form data is invalid');
      return;
    }

    this.auth.loginViaEmailPassword(this.form.value.email, this.form.value.password)
        .catch(error => {
          console.log('FAILED', error.code, error.message, error);
          switch(error.code) {
            case 'auth/invalid-email':
              this.errorMessage = 'Please enter a valid email address and try again.';
              break;
            case 'auth/user-not-found':
              this.errorMessage = 'We don\'t have a user with that email address. Please check it and try again.';
              break;
            case 'auth/wrong-password':
              this.errorMessage = 'The password was incorrect. Please check it and try again.';
              break;
            default:
              this.errorMessage = error.message;
          }
        });
  }

  logout() {
    this.auth.logout();
  }
}

import { CampaignActions } from './campaign.actions';
import { ContactActions } from './contact.actions';
import { ConversationActions } from './conversation.actions';
import { MessageActions } from './message.actions';
import { UserActions } from './user.actions';
import { UsersForCampaignActions } from './users-for-campaign.actions';
import { SettingsActions } from './settings.actions';
import { TagActions } from './tag.actions';
import { ActionWithPayload } from './action-with-payload';

export {
  ActionWithPayload,
  CampaignActions,
  ContactActions,
  ConversationActions,
  MessageActions,
  UserActions,
  UsersForCampaignActions,
  SettingsActions,
  TagActions
};

export default [
  CampaignActions,
  ContactActions,
  ConversationActions,
  MessageActions,
  UserActions,
  UsersForCampaignActions,
  SettingsActions,
  TagActions
];

import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OrgIdService {
  orgId: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    ).subscribe(e => {
      this.orgId = this.router.routerState.snapshot.root.firstChild.params.orgId;
    });
  }

  getOrgId(): string {
    return this.orgId;
  }
}

import { Observable } from 'rxjs';
import { Action } from '@ngrx/store';

import { ActionWithPayload, UsersForCampaignActions } from '../actions';

export function usersForCampaignsReducer(state: any = {}, action: ActionWithPayload<any>):any {
  switch(action.type) {
    case UsersForCampaignActions.LOAD_FOR_CAMPAIGN_SUCCESS:
      if (action != null) {
        const val = (!action.payload.value) ? {} : action.payload.value;
        return { ...state, [action.payload.campaignId]: { ...val } };
      }
      return state;
    default:
      return state;
  }
};

<section class="slice slice-navbar bg-gradient-primary" data-separator-bg="secondary"><div></div></section>

<section class="slice slice-lg">
  <div class="container pt-lg-md">
      <div class="row justify-content-center">
          <div class="col-lg-9">
              <div class="card shadow-lg">
                  <div class="card-body px-lg-5 py-lg-5">
                      <div class="text-center mb-4">
                          <h2 class="parlance h2 pt-3">parlance</h2>
                          <h4 class="heading h4 pt-2">Change Your Password</h4>
                      </div>
                      <span class="clearfix"></span>

                      <form role="form" [formGroup]="passwordForm">
                        <h3 class="heading h3 mt-6">Change Password</h3>
                        <div>You are required to change your password. Please re-enter your current password, along with a new password below.</div>

                        <div class="row">
                          <div class="col-lg-6">
                            <div class="input-group mb-4">
                              <input [type]="showOriginalPassword ? 'text' : 'password'" class="form-control form-control-lg form-control-emphasized" placeholder="Current Password" formControlName="currentPassword">
                              <span class="input-group-text">
                                <i class="fa" [ngClass]="{ 'fa-eye-slash': !showOriginalPassword, 'fa-eye': showOriginalPassword }" (click)="toggleShowOriginalPassword()"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-6">
                            <div class="input-group mb-4">
                              <input [type]="showPassword1 ? 'text' : 'password'" class="form-control form-control-lg form-control-emphasized" placeholder="New Password" formControlName="password">
                              <span class="input-group-text">
                                <i class="fa" [ngClass]="{ 'fa-eye-slash': !showPassword1, 'fa-eye': showPassword1 }" (click)="toggleShowPassword1()"></i>
                              </span>
                            </div>
                            <div class="input-group mb-4">
                              <input [type]="showPassword2 ? 'text' : 'password'" class="form-control form-control-lg form-control-emphasized" placeholder="Confirm Password" formControlName="passwordConfirm">
                              <span class="input-group-text">
                                <i class="fa" [ngClass]="{ 'fa-eye-slash': !showPassword2, 'fa-eye': showPassword2 }" (click)="toggleShowPassword2()"></i>
                              </span>
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <p class="text-muted">
                              <i *ngIf="!password.dirty && password.errors?.missingUpperCase" class="fas fa-times-circle text-muted"></i>
                              <i *ngIf="password.dirty && password.errors?.missingUpperCase" class="fas fa-times-circle text-danger"></i>
                              <i *ngIf="!password.errors?.missingUpperCase" class="fas fa-check-circle text-success"></i>
                              Has uppercase letter<br/>

                              <i *ngIf="!password.dirty && password.errors?.missingLowerCase" class="fas fa-times-circle text-muted"></i>
                              <i *ngIf="password.dirty && password.errors?.missingLowerCase" class="fas fa-times-circle text-danger"></i>
                              <i *ngIf="!password.errors?.missingLowerCase" class="fas fa-check-circle text-success"></i>
                              Has lowercase letter<br/>

                              <ng-container *ngIf="false">
                              <i *ngIf="!password.dirty && password.errors?.missingNumber" class="fas fa-times-circle text-muted"></i>
                              <i *ngIf="password.dirty && password.errors?.missingNumber" class="fas fa-times-circle text-danger"></i>
                              <i *ngIf="!password.errors?.missingNumber" class="fas fa-check-circle text-success"></i>
                              Has a number<br/>
                              </ng-container>

                              <ng-container *ngIf="false">
                              <i *ngIf="!password.dirty && password.errors?.missingSymbol" class="fas fa-times-circle text-muted"></i>
                              <i *ngIf="password.dirty && password.errors?.missingSymbol" class="fas fa-times-circle text-danger"></i>
                              <i *ngIf="!password.errors?.missingSymbol" class="fas fa-check-circle text-success"></i>
                              Has a sybmol (!@#$%^&amp;*)<br/>
                              </ng-container>

                              <i *ngIf="!password.dirty && password.errors?.tooShort" class="fas fa-times-circle text-muted"></i>
                              <i *ngIf="password.dirty && password.errors?.tooShort" class="fas fa-times-circle text-danger"></i>
                              <i *ngIf="!password.errors?.tooShort" class="fas fa-check-circle text-success"></i>
                              At least 6 characters long<br/>

                              <i *ngIf="!password.dirty && !confirmPassword.dirty" class="fas fa-times-circle text-muted"></i>
                              <i *ngIf="(password.dirty || confirmPassword.dirty) && password.errors?.notEqual" class="fas fa-times-circle text-danger"></i>
                              <i *ngIf="(password.dirty || confirmPassword.dirty) && !password.errors?.notEqual" class="fas fa-check-circle text-success"></i>
                              Passwords match<br/>
                            </p>
                          </div>
                        </div>

                        <button class="btn btn-primary" (click)="savePassword()" [disabled]="!passwordForm.valid">Change Password</button>
                      </form>

                  </div>

              </div>
          </div>
      </div>
  </div>
</section>

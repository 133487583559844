import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Observable, of } from 'rxjs';
import { catchError, switchMap, exhaustMap, map, mergeMap, tap, filter } from 'rxjs/operators';

import { OrgIdService } from '../../services';
import { ActionWithPayload, UsersForCampaignActions } from '../actions';

import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Injectable()
export class UsersForCampaignEffects {
  constructor(
    private actions$: Actions,
    private actions: UsersForCampaignActions,
    private db: AngularFireDatabase,
    private firebaseFunctions: AngularFireFunctions,
    private orgIdService: OrgIdService
  ) {}

  
  loadForCampaign$ = createEffect(() => this.actions$.pipe(
    ofType<ActionWithPayload<string>>(UsersForCampaignActions.LOAD_FOR_CAMPAIGN),
    switchMap(action => {
      return this.db.object(`/organizations/${this.orgIdService.getOrgId()}/users-for-campaign/${action.payload}`).snapshotChanges();
    }),
    catchError( (err, caught) => {
      console.warn('Error in UsersForCampaignEffects.loadForCampaign$', err, caught);
      return of(null);
    }),
    filter(v => !!v),
    map(action => {
      const obj = action.payload.val();
      const campId = action.payload.key;
      return {
        type: UsersForCampaignActions.LOAD_FOR_CAMPAIGN_SUCCESS,
        payload: { campaignId: campId, value: obj }
      };
    })
  ));

  
  update$ = createEffect(() => this.actions$.pipe(
    ofType<ActionWithPayload<{campaignId:string, userIds: any}>>(UsersForCampaignActions.UPDATE),
    tap((action) => {
      this.db.object(`/organizations/${this.orgIdService.getOrgId()}/users-for-campaign/${action.payload.campaignId}`).set(action.payload.userIds).then(
        (retVal) => {
          // console.log('Update success', retVal);
          // this.store.dispatch(this.actions.saveSuccess());
        }, (error: Error) => {
          console.log('Error pushing', error);
        }
      );
      // this.service.saveContact(action.payload)),
    }),
    catchError( (err, caught) => {
      console.warn('Error in UsersForCampaignEffects.update$', err, caught);
      return of(null);
    }),
    filter(() => false)
  ));

  
  add$ = createEffect(() => this.actions$.pipe(
    ofType<ActionWithPayload<{campaignId:string, userIds: any}>>(UsersForCampaignActions.ADD),
    tap((action) => {
      console.log('Add campaign users', action.payload.campaignId, action.payload.userIds);

      this.firebaseFunctions.httpsCallable('addUsersToCampaign')({org: this.orgIdService.getOrgId(), campaignId: action.payload.campaignId, userIds: action.payload.userIds}).toPromise().then(function(result:any) {
        // Read result of the Cloud Function.
        // console.log('Got result from add users to campaign', result);
        if (!result.success) {
          console.log('Add users to campaign error', result);
          alert('An unknown error occurred adding users to the campaign');
        }
      }).catch(function(error) {
        // Getting the Error details.
        console.log('Got error from add users to campaign', error);
        let code = error.code;
        let message = error.message;
        let details = error.details;
        alert('Error!\n' + error.message);
      });
    }),
    filter(() => false)
  ));

  
  remove$ = createEffect(() => this.actions$.pipe(
    ofType<ActionWithPayload<{campaignId:string, userIds: any}>>(UsersForCampaignActions.REMOVE),
    tap((action) => {
      console.log('Remove campaign users', action.payload.campaignId, action.payload.userIds);

      this.firebaseFunctions.httpsCallable('removeUsersFromCampaign')({org: this.orgIdService.getOrgId(), campaignId: action.payload.campaignId, userIds: action.payload.userIds}).toPromise().then(function(result:any) {
        // Read result of the Cloud Function.
        console.log('Got result from remove users from campaign', result);
        if (!result.success) {
          console.log('Remove users from campaign error', result);
          alert('An unknown error occurred removing users from the campaign');
        }
      }).catch(function(error) {
        // Getting the Error details.
        console.log('Got error from remove users from campaign', error);
        let code = error.code;
        let message = error.message;
        let details = error.details;
        alert('Error!\n' + error.message);
      });
    }),
    filter(() => false)
  ));
}

import { Observable } from 'rxjs';
import { Action } from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createSelector, createFeatureSelector } from '@ngrx/store';

import { ActionWithPayload, SettingsActions } from '../actions';
import { Settings } from '../../model';

export function settingsReducer(state: any = {}, action: ActionWithPayload<any>) {
  switch(action.type) {
    case SettingsActions.LOAD_SUCCESS:
      const val = new Settings(action.payload);
      return val;
    default:
      return state;
  }
}

import { Component, OnInit, Injectable } from '@angular/core';
import { AbstractControl, AsyncValidator, UntypedFormBuilder, FormControl, UntypedFormGroup, ValidationErrors, Validators, ValidatorFn } from '@angular/forms';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Component({
  selector: 'app-get-started',
  templateUrl: './get-started.component.html',
  styleUrls: ['./get-started.component.scss']
})
export class GetStartedComponent implements OnInit {
  form: UntypedFormGroup;
  processing: boolean;
  success: boolean;
  errorMessage: string;

  constructor(
    private fb: UntypedFormBuilder,
    private firebaseFunctions: AngularFireFunctions
  ) { }

  ngOnInit() {
    this.createForm();
    this.success = false;
    this.processing = false;
    this.errorMessage = undefined;
  }

  createForm() {
    this.form = this.fb.group({
      name: this.fb.control('', Validators.required),
      organization: this.fb.control('', Validators.required),
      email: this.fb.control('', [Validators.required, Validators.email]),
      phone: this.fb.control('', Validators.required)
    });
  }

  doSignUp() {
    if (!this.form.valid || this.processing) return;
    this.processing = true;
    let _this = this;

    const fv = this.form.value;
    const data = {
      name: fv.name,
      organization: fv.organization,
      email: fv.email,
      phone: fv.phone
    };

    this.firebaseFunctions.httpsCallable('requestDemo')({data: data}).toPromise().then(function(result:any) {
      console.log("Got result from demo request", result);
      _this.processing = false;
      if (result.success) {
        _this.success = true;
      } else {
        _this.success = false;
        _this.errorMessage = "We're sorry, but an error occurred. Please try again or email us at sales@parlance.app";
      }
    }).catch(function(error) {
      console.log("Got error from avail check", error);
      _this.success = false;
      _this.processing = false;
      _this.errorMessage = error.message ? error.message : "We're sorry, but an error occurred. Please try again or email us at sales@parlance.app";
      return { networkError: true };
    });
  }
}

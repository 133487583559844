<section class="slice slice-navbar bg-gradient-primary" data-separator-bg="secondary"><div></div></section>

<section class="slice slice-lg">
  <div class="container pt-lg-md">
      <div class="row justify-content-center">
          <div class="col-lg-8">
              <div class="card shadow-lg">
                  <div class="card-body px-lg-5 py-lg-5">
                      <div class="text-center mb-4" *ngIf="success">
                        <h2 class="heading h2 pt-3">Thank you!</h2>
                        We've sent your information to our sales team and we will be in touch shortly!
                        If you have any questions in the meantime, feel free to email us at <a href="mailto:sales@parlance.app">sales@parlance.app</a>
                        <a [routerLink]="['/']" class="btn btn-primary mt-4">Keep Learning More</a>
                      </div>
                      <div *ngIf="!success">
                        <div class="text-center mb-4">
                            <h2 class="parlance h2 pt-3">parlance</h2>
                            <h4 class="heading h4 pt-2">Request a demo &amp; talk with our sales team</h4>
                            <p class="text-muted">(Looking to <a [routerLink]="['/login']">sign-in as a user</a>?)</p>
                        </div>
                        <span class="clearfix"></span>
                        <div class="alert alert-danger mb-4 mt-4" *ngIf="errorMessage">
                          {{errorMessage}}
                        </div>
                        <form role="form" [formGroup]="form">
                            <div class="form-group mb-4">
                                <input type="text" class="form-control form-control-lg form-control-emphasized" placeholder="Your name" formControlName="name">
                            </div>
                            <div class="form-group mb-4">
                                <input type="text" class="form-control form-control-lg form-control-emphasized" placeholder="Organization" formControlName="organization">
                            </div>
                            <div class="form-group mb-4">
                                <input type="email" class="form-control form-control-lg form-control-emphasized" placeholder="Your email" formControlName="email">
                            </div>
                            <div class="form-group mb-4">
                                <input type="tel" class="form-control form-control-lg form-control-emphasized" placeholder="Cell phone number" formControlName="phone">
                            </div>
                            <div class="text-center">
                              <button type="submit" class="btn btn-lg btn-primary" (click)="doSignUp()" [disabled]="!form.valid || processing">
                                <span *ngIf="!processing">Get started</span>
                                <span *ngIf="processing"><i class="fas fa-spinner fa-spin"></i> Please wait</span>
                              </button>
                            </div>
                            <div class="text-muted text-center mt-4">
                              Prefer to email us? We're at <a href="mailto:sales@parlance.app">sales@parlance.app</a>
                            </div>
                        </form>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>

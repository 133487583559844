import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-main',
  templateUrl: './main.page.html',
  styleUrls: ['./main.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MainPage implements OnInit {
  isCollapsed;

  constructor() {
  }

  ngOnInit() {
    this.isCollapsed = true;
  }

}

<section class="slice slice-xl bg-gradient-primary bg-cover bg-size--cover" data-separator="rounded-continuous" data-separator-bg="secondary">
  <div class="container">
      <div class="row row-grid justify-content-around align-items-center">
          <div class="col-lg-6">
              <div class="pt-lg-md text-center">
                  <h2 class="h1 text-white mb-3">Powerful Communication</h2>
                  <p class="lead text-white lh-180">Reach more people, with more success, and communicate with them via the method they prefer</p>
                  <div class="mt-5">
                      <a [routerLink]="['/pricing']" class="btn btn-white btn-circle btn-translate--hover me-3">Pricing</a>
                      <a target="_blank" href="https://calendly.com/parlanceapp/parlance-introductory-call" class="btn btn-info btn-circle btn-translate--hover">Get a Demo</a>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>

<section class="bg-secondary">
  <div class="container">
      <div class="row row-grid">
          <div class="col-lg-4">
              <div class="card shadow-sm shadow--hover" style="height: 100%">
                  <div class="py-5 text-center">
                      <i class="fal fa-comments fa-6x"></i>
                  </div>
                  <div class="px-4 pb-5 text-center">
                      <h5 class="h4">Texting &amp; Email</h5>
                      <p class="mt-2">
                        Why be limited to a single channel? Texting often generates faster results, but others still prefer email. With Parlance, do both and engage contacts more effectively.
                      </p>
                  </div>
              </div>
          </div>
          <div class="col-lg-4">
              <div class="card shadow-sm shadow--hover" style="height: 100%">
                  <div class="py-5 text-center">
                      <i class="fal fa-poll-people fa-6x"></i>
                  </div>
                  <div class="px-4 pb-5 text-center">
                      <h5 class="h4">Manage Volunteers</h5>
                      <p class="mt-2">
                        Track the effectiveness of volunteers, see their conversations, and provide the assistance they need. With Parlance, know if someone needs more motivation to meet goals.
                      </p>
                  </div>
              </div>
          </div>
          <div class="col-lg-4">
              <div class="card shadow-sm shadow--hover" style="height: 100%">
                  <div class="py-5 text-center">
                      <i class="fal fa-users fa-6x"></i>
                  </div>
                  <div class="px-4 pb-5 text-center">
                      <h5 class="h4">Family Support</h5>
                      <p class="mt-2">
                        Avoid harassing dad when mom already gave. With Parlance, you have the ability to combine multiple people into a single family, keeping all your interactions with them in one spot. Track results as families instead of individuals.
                      </p>
                  </div>
              </div>
          </div>
      </div>
      <div class="row row-grid">
          <div class="offset-lg-2 col-lg-4">
              <div class="card shadow-sm shadow--hover" style="height: 100%">
                  <div class="py-5 text-center">
                      <i class="fal fa-fingerprint fa-6x"></i>
                  </div>
                  <div class="px-4 pb-5 text-center">
                      <h5 class="h4">Personalized</h5>
                      <p class="mt-2">
                        Communication is more effective when it's personalized. Create templates that fill in a contact's details to personalize your messaging, and that are based upon the contact's status (such as &ldquo;never given&rdquo; vs. &ldquo;gave last year&rdquo;). With Parlance, messaging is targeted and personalized.
                      </p>
                  </div>
              </div>
          </div>
          <div class="col-lg-4">
              <div class="card shadow-sm shadow--hover" style="height: 100%">
                  <div class="py-5 text-center">
                      <i class="fab fa-app-store-ios fa-6x"></i>
                  </div>
                  <div class="px-4 pb-5 text-center">
                      <h5 class="h4">No App Required</h5>
                      <p class="mt-2">
                        Anywhere you have a web browser, you can use Parlance. Without requiring your volunteers to install an app, they can work from laptop, tablet, phone, allowing volunteers to use the device of their preference.
                      </p>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>

<section class="slice slice-lg">
  <div class="container">
      <div class="mb-md text-center">
          <h3>Great uses for Parlance</h3>
          <div class="fluid-paragraph text-center mt-4">
              <p class="lead lh-180">Parlance was designed to let you engage in large numbers of conversations in a short period of time, but it can be used for so much more.</p>
          </div>
      </div>
      <div class="row row-grid">
          <div class="col-md-6">
              <div class="card">
                  <div class="card-body py-5">
                      <div class="d-flex align-items-start">
                          <div class="icon text-primary">
                              <i class="fal fa-sack-dollar"></i>
                          </div>
                          <div class="icon-text">
                              <h5 class="h5">Fundraising</h5>
                              <p class="mb-0">Rapidly engage contacts and raise funds faster than traditional phone banks or email blasts</p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="col-md-6">
              <div class="card">
                  <div class="card-body py-5">
                      <div class="d-flex align-items-start">
                          <div class="icon text-primary">
                              <i class="fal fa-calendar-star"></i>
                          </div>
                          <div class="icon-text">
                              <h5 class="h5">Events</h5>
                              <p class="mb-0">Gather RSVPs quickly by personally inviting and conversing with guests</p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="row row-grid">
          <div class="col-md-6">
              <div class="card">
                  <div class="card-body py-5">
                      <div class="d-flex align-items-start">
                          <div class="icon text-primary">
                              <i class="fal fa-calendar-star"></i>
                          </div>
                          <div class="icon-text">
                              <h5 class="h5">Updates &amp; Reminders</h5>
                              <p class="mb-0">Blast out text messages to a targeted list and engage any responses in conversation</p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="col-md-6">
              <div class="card">
                  <div class="card-body py-5">
                      <div class="d-flex align-items-start">
                          <div class="icon text-primary">
                              <i class="fal fa-lightbulb"></i>
                          </div>
                          <div class="icon-text">
                              <h5 class="h5">Any much more</h5>
                              <p class="mb-0">Anytime you need to engage in real conversations with your audience</p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>

<section class="slice slice-lg bg-secondary">
    <span class="tongue tongue-top"><svg class="svg-inline--fa fa-angle-up fa-w-10" aria-hidden="true" data-prefix="fas" data-icon="angle-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg=""><path fill="currentColor" d="M177 159.7l136 136c9.4 9.4 9.4 24.6 0 33.9l-22.6 22.6c-9.4 9.4-24.6 9.4-33.9 0L160 255.9l-96.4 96.4c-9.4 9.4-24.6 9.4-33.9 0L7 329.7c-9.4-9.4-9.4-24.6 0-33.9l136-136c9.4-9.5 24.6-9.5 34-.1z"></path></svg><!-- <i class="fas fa-angle-up"></i> --></span>
    <div class="container">
        <div class="row justify-content-center align-items-center">
            <div class="col-lg-8 text-center">
                <h3 class="h3 font-weight-400">I'm ready to start conversing with <span class="font-weight-700">parlance</span>!</h3>
                <div class="mt-5">
                    <a target="_blank" href="https://calendly.com/parlanceapp/parlance-introductory-call" class="btn btn-primary btn-circle btn-translate--hover px-4">Get a Demo</a>
                    <a [routerLink]="['/pricing']" class="btn btn-link">See Pricing</a>
                </div>
                <div class="mt-5">
                    <a href="mailto:support@parlance.app?subject=Parlance Support" class="btn btn-link">Need support?</a>
                </div>
            </div>
        </div>
    </div>
</section>

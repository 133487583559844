import {combineReducers, createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';
import { compose } from '@ngrx/store';
import { ActionReducerMap } from '@ngrx/store';

import {
  CampaignState,
  Family,
  ContactState,
  Settings,
  Tag,
  TagState,
  User,
  UserState,
  ConversationSummary,
  Conversation,
  Campaign
} from '../model';

import * as Reducers from './reducers';
import { selectUserState, userAdapter } from './reducers/users.reducer';
import { selectContactsState, contactsAdapter } from './reducers/contacts.reducer';
import { selectCampaignState, campaignAdapter } from './reducers/campaigns.reducer';
import { selectTagsState, tagsAdapter } from './reducers/tags.reducer';

export interface AppStore {
  campaigns: CampaignState;
  contacts: ContactState;
  contactDictionary: {[key: string]: Family};
  conversationsById: object;
  conversationSummaries: { [campaignId: string]: { [contactId: string]: ConversationSummary } };
  unreads: object;
  messagesByConversationId: { [campaignId: string]: { [contactId: string]: ConversationSummary } };
  settings: Settings;
  tags: TagState;
  tagDictionary: {[key: string]: Tag};
  user: User;
  userDictionary: {[key: string]: User};
  users: UserState;
  usersForCampaigns: object;
  userToCampaigns: object;
  userToCampaignConversations: object;
}

export const reducers: ActionReducerMap<AppStore> = {
  campaigns: Reducers.campaignsReducer,
  contacts: Reducers.contactsReducer,
  contactDictionary: Reducers.contactDictionary,
  conversationsById: Reducers.conversationReducer,
  conversationSummaries: Reducers.conversationsSummariesReducer,
  unreads: Reducers.unreadReducer,
  messagesByConversationId: Reducers.messagesReducer,
  settings: Reducers.settingsReducer,
  tags: Reducers.tagsReducer,
  tagDictionary: Reducers.tagDictionary,
  user: Reducers.user,
  userDictionary: Reducers.userDictionary,
  users: Reducers.userReducer,
  usersForCampaigns: Reducers.usersForCampaignsReducer,
  userToCampaigns: Reducers.userToCampaignsReducer,
  userToCampaignConversations: Reducers.userToCampaignConversationsReducer,
};

export const selectConverationsByIdState: MemoizedSelector<object, AppStore> = createFeatureSelector<AppStore>('conversationsById');
const getConversationsById = (state: any): any => state;
export const selectConversationsById: MemoizedSelector<object, any> = createSelector(selectConverationsByIdState, getConversationsById);
// MemoizedSelector<object, { [campaignId: string]: { [contactId: string]: Conversation } }>
export const selectConversationsByIdForCampaign = (campaignId: string) =>
  createSelector(selectConversationsById, (allConvos: { [campaignId: string]: { [contactId: string]: Conversation } }) => {
  return (allConvos && allConvos[campaignId]) ? allConvos[campaignId] : {};
});

const allState = (state: AppStore): AppStore => state;
const getConversationSummaries = (state: AppStore): { [campaignId: string]: { [contactId: string]: ConversationSummary } } => state.conversationSummaries;
// const getCampaigns = (state: AppStore): { [campaignId: string]: Campaign } => state.campaigns;

export const selectConversationSummaries: MemoizedSelector<object, { [campaignId: string]: { [contactId: string]: ConversationSummary } }> = createSelector(allState, getConversationSummaries);
export const selectConversationSummariesForCampaign = (campaignId: string) =>
  createSelector(selectConversationSummaries, (allSummaries: { [campaignId: string]: { [contactId: string]: ConversationSummary } }) => {
  return (allSummaries && allSummaries[campaignId]) ? allSummaries[campaignId] : {};
});

export const selectConversationSummaryForContactInCampaign = (campaignId: string, contactId: string) =>
  createSelector(selectConversationSummaries, (allSummaries: { [campaignId: string]: { [contactId: string]: ConversationSummary } }): ConversationSummary => {
    return (allSummaries && allSummaries[campaignId] && allSummaries[campaignId][contactId]) ? allSummaries[campaignId][contactId] : undefined;
  });

export const selectConversationsForCampaign = (id: string) => createSelector(selectConversationsById, (convos: any) => {
  // console.log('SELECTING CONVOS FOR CAMP', id, convos);
  return convos ? (convos[id] || {}) : {};
});

export const selectConversationForCampaignAndId = (campaignId: string, contactId: string) => createSelector(selectConversationsById, (convos: any) => {
  // console.log('SELECTING CONVO', campaignId, contactId);
  if ((!convos) || (!convos[campaignId]) || (!convos[campaignId][contactId])) return {};
  return convos[campaignId][contactId];
});

export const {
  selectIds: selectUserIds,
  selectEntities: selectUserEntities,
  selectAll: selectAllUsers,
  selectTotal: selectUserTotal,
} = userAdapter.getSelectors(selectUserState);

export const {
  selectIds: selectContactIds,
  selectEntities: selectContactEntities,
  selectAll: selectAllContacts,
  selectTotal: selectContactTotal,
} = contactsAdapter.getSelectors(selectContactsState);

export const {
  selectIds: selectCampaignIds,
  selectEntities: selectCampaignEntities,
  selectAll: selectAllCampaigns,
  selectTotal: selectCampaignTotal,
} = campaignAdapter.getSelectors(selectCampaignState);

export const {
  selectIds: selectTagIds,
  selectEntities: selectTagEntities,
  selectAll: selectAllTags,
  selectTotal: selectTagTotal,
} = tagsAdapter.getSelectors(selectTagsState);

// export const selectCampaignById: MemoizedSelector<object, Campaign> = (campaignId: string) =>
//   createSelector(selectAllCampaigns, (allCampaigns: { [campaignId]: string: Campaign }) => {
//     return (allCampaigns && allCampaigns[campaignId]) ? allCampaigns[campaignId] : undefined;
//   });

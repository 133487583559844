export enum MessageMethod {
  SMS = "SMS",
  Email = "EMAIL",
  Phone = "PHONE"
}

export class Message {
  id?: string;
  fromUser?: string;
  fromContact?: string;
  method: MessageMethod;
  text: string;
  timestamp: number;
  reminder?: boolean;
  blast?: boolean;
}

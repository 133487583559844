import { Component, OnInit, Injectable } from '@angular/core';
import { AbstractControl, AsyncValidator, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators, ValidatorFn } from '@angular/forms';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import { UniqueOrgIdValidator } from './unique-org-id-validator';

@Component({
  selector: 'app-org-sign-up',
  templateUrl: './org-sign-up.component.html',
  styleUrls: ['./org-sign-up.component.scss']
})
export class OrgSignUpComponent implements OnInit {
  form: UntypedFormGroup;
  processing: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private uniqueOrgIdValidator: UniqueOrgIdValidator,
    private firebaseFunctions: AngularFireFunctions
  ) { }

  ngOnInit() {
    this.createForm();
    this.processing = false;
  }

  createForm() {
    const pwd:UntypedFormControl = this.fb.control('');
    const pwdConfirm:UntypedFormControl = this.fb.control('', [Validators.required]);
    pwd.setValidators([Validators.required, strongPasswordValidator(pwdConfirm, false, true)]);
    pwdConfirm.setValidators([Validators.required, strongPasswordValidator(pwd, true, true)]);

    this.form = this.fb.group({
      orgId: this.fb.control('', [Validators.required, Validators.minLength(3), Validators.maxLength(30), Validators.pattern(/^[\w-]+$/)], this.uniqueOrgIdValidator.validate.bind(this.uniqueOrgIdValidator)),
      orgName: this.fb.control('', Validators.required),
      orgAddress: this.fb.control('', Validators.required),
      orgCity: this.fb.control('', Validators.required),
      orgState: this.fb.control('', Validators.required),
      orgZip: this.fb.control('', Validators.required),
      firstName: this.fb.control('', Validators.required),
      lastName: this.fb.control('', Validators.required),
      title: this.fb.control('', Validators.required),
      email: this.fb.control('', [Validators.required, Validators.email]),
      password: pwd,
      passwordConfirm: pwdConfirm,
      phone: this.fb.control('', Validators.required),
      areaCode: this.fb.control(''),
      giftNet: this.fb.control(false),
    });
  }

  get orgId() { return this.form.get('orgId'); }

  get password() { return this.form.get('password'); }

  get confirmPassword() { return this.form.get('passwordConfirm'); }

  doSignUp() {
    if (!this.form.valid || this.processing) return;
    this.processing = true;
    let _this = this;

    const fv = this.form.value;
    const org = {
      id: fv.orgId,
      name: fv.orgName,
      address: fv.orgAddress,
      city: fv.orgCity,
      state: fv.orgState,
      zip: fv.orgZip,
      areaCode: fv.areaCode,
      giftNet: !!fv.giftNet
    };

    const user = {
      email: fv.email,
      firstName: fv.firstName,
      lastName: fv.lastName,
      phoneNumber: fv.phone,
      position: fv.title
    };

    this.firebaseFunctions.httpsCallable('processOrgSignUp')({org: org, user: user, password: fv.password}).toPromise().then(function(result:any) {
      // Read result of the Cloud Function.
      console.log("Got result from sign-up", result);
      _this.processing = false;
      if (result.success) {
        alert("Success!");
      } else {
        alert("An unknown error occurred");
      }
    }).catch(function(error) {
      // Getting the Error details.
      console.log("Got error from avail check", error);
      var code = error.code;
      var message = error.message;
      var details = error.details;
      _this.processing = false;
      alert("Error!\n" + error.message);
      return { networkError: true };
    });
  }
}

export function strongPasswordValidator(confirmPasswordCtrl: UntypedFormControl, reverse:boolean = false, relaxed:boolean = false): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    if (reverse) {
      // Doing just equality check and setting in reverse
      if (control.value === confirmPasswordCtrl.value) {
        if (confirmPasswordCtrl.errors) {
          let newErrors = confirmPasswordCtrl.errors;
          delete newErrors['notEqual'];
          if (Object.keys(newErrors).length == 0) newErrors = null;
          confirmPasswordCtrl.setErrors(newErrors);
        }
      } else {
        const newErrors = confirmPasswordCtrl.errors || {};
        newErrors.notEqual = true;
        confirmPasswordCtrl.setErrors(newErrors);
      }

      return confirmPasswordCtrl.errors;
    }

    // let hasNumber = /\d/.test(control.value);
    let hasUpper = /[A-Z]/.test(control.value);
    let hasLower = /[a-z]/.test(control.value);
    // let hasSymbol = /[!@#$%^&*\(\)]/.test(control.value);
    let hasLength = control.value.length >= 6;
    let equals = control.value === confirmPasswordCtrl.value;

    // console.log('Num, Upp, Low', hasNumber, hasUpper, hasLower);
    //if (!(hasNumber && hasUpper && hasLower && hasSymbol && hasLength && equals)) {
    if (!(hasUpper && hasLower && hasLength && equals)) {
      let result:any = {};
      if (!relaxed) {
        //if (!hasNumber) result.missingNumber = true;
        if (!hasUpper) result.missingUpperCase = true;
        if (!hasLower) result.missingLowerCase = true;
        //if (!hasSymbol) result.missingSymbol = true;
      }
      if (!hasLength) result.tooShort = true;
      if (!equals) result.notEqual = true;

      confirmPasswordCtrl.setErrors(result);
      return result;
    } else {
      confirmPasswordCtrl.setErrors(null);
      return null;
    }
  };
}

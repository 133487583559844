import { Injectable, HostListener } from '@angular/core';
import { CanDeactivate } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateGuard implements CanDeactivate<ComponentCanDeactivate> {
  canDeactivate(component: ComponentCanDeactivate): boolean {
    console.log(component);
    if(!component.canDeactivate()){
      if (confirm("You have not selected a call result! Are you sure you want to leave this conversation?")) {
          return true;
      } else {
          return false;
      }
    }

    return true;
  }

  constructor() { }
}

@Injectable()
export abstract class ComponentCanDeactivate {
  abstract canDeactivate(): boolean;

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
      if (!this.canDeactivate()) {
          $event.returnValue =true;
      }
  }
}

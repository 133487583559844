import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NavTitleService {
  title: BehaviorSubject<string>;

  constructor(private router: Router) {
    this.title = new BehaviorSubject('Parlance');
  }

  setTitle(newTitle:string) {
    this.title.next(newTitle);
  }

  getTitle$() {
    return this.title;
  }
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Observable, of } from 'rxjs';
import {catchError, switchMap, exhaustMap, map, mergeMap, tap, take, filter, debounceTime, throttleTime, auditTime} from 'rxjs/operators';

import { ActionWithPayload, CampaignActions } from '../actions';
import { CampaignService, OrgIdService } from '../../services';
import { Campaign } from '../../model';

import { AngularFireDatabase } from '@angular/fire/compat/database';

@Injectable()
export class CampaignEffects {
  constructor(
    private actions$: Actions,
    private service: CampaignService,
    private actions: CampaignActions,
    private db: AngularFireDatabase,
    private orgIdService: OrgIdService
  ) {}

  
  loadAll$ = createEffect(() => this.actions$.pipe(
    ofType(CampaignActions.LOAD_ALL),
    switchMap(action => {
      return this.db.object(`/organizations/${this.orgIdService.getOrgId()}/campaigns`).snapshotChanges()
    }),
    catchError( (err, caught) => {
      console.warn('Error in CampaignEffects.loadAll$', err, caught);
      return of(null);
    }),
    filter(v => !!v),
    auditTime(250),
    map(action => {
      const obj = action.payload.val() || {};
      return {
        type: `[Campaign] ${action.type}`,
        payload: Object.keys(obj).map(key => ({id:key, ...obj[key]}))
      };
    })
  ));

  
  saveCampaign$ = createEffect(() => this.actions$.pipe(
    ofType<ActionWithPayload<Partial<Campaign>>>(CampaignActions.SAVE),
    tap((action) => this.service.saveCampaign(action.payload)),
    filter(() => false)
  ));

  
  duplicateCampaign$ = createEffect(() => this.actions$.pipe(
    ofType<ActionWithPayload<{ campaign: Partial<Campaign>, duplicatePhoneNumbers: boolean }>>(CampaignActions.DUPLICATE),
    tap((action) => this.service.duplicateCampaign(action.payload.campaign, action.payload.duplicatePhoneNumbers)),
    filter(() => false)
  ));

  
  archiveCampaign$ = createEffect(() => this.actions$.pipe(
    ofType<ActionWithPayload<{ campaignId: string }>>(CampaignActions.ARCHIVE),
    tap((action) => this.service.archiveCampaign(action.payload.campaignId)),
    filter(() => false)
  ));

  
  loadCampaignsForUser$ = createEffect(() => this.actions$.pipe(
    ofType<ActionWithPayload<{userId:string}>>(CampaignActions.LOAD_CAMPAIGNS_FOR_USER),
    switchMap(action => {
      return this.db.object(`/organizations/${this.orgIdService.getOrgId()}/user-to-campaigns/${action.payload.userId}`).snapshotChanges();
    }, (origValue, newValue) => ({ userId: origValue.payload.userId, snapshot: newValue })),
    catchError( (err, caught) => {
      console.warn('Error in CampaignEffects.loadCampaignsForUser$', err, caught);
      return of(null);
    }),
    filter(v => !!v),
    switchMap(snap => {
      const obj = snap.snapshot.payload.val() || {};
      const arr = Object.keys(obj).map(campaignId => {
        return this.db.database.ref(`/organizations/${this.orgIdService.getOrgId()}/campaigns/${campaignId}`).once('value').then(c => {
          const ret = {};
          ret[campaignId] = c.val();
          return ret;
        });
      });
      return Promise.all(arr);
    }, (origValue, newValue) => { return { userId: origValue.userId, value: newValue } }),
    map(v => {
      const dict = {};
      v.value.forEach(camp => {
        Object.keys(camp).forEach(key => { dict[key] = camp[key] })
      });
      return {
        type: CampaignActions.LOAD_CAMPAIGNS_FOR_USER_SUCCESS,
        payload: { [v.userId]: dict }
      }
    })
  ));
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Store } from '@ngrx/store';

import { Campaign } from '../model';
import { CampaignActions } from '../store/actions';
import { AppStore } from '../store/app.store';
import { OrgIdService } from './org-id.service';

@Injectable({
  providedIn: 'root'
})
export class CampaignService {

  constructor(
    private db: AngularFireDatabase,
    private actions: CampaignActions,
    private store: Store<AppStore>,
    private router: Router,
    private firebaseFunctions: AngularFireFunctions,
    private orgIdService: OrgIdService) {
  }

  loadAll() {
    return this.db.object(`/organizations/${this.orgIdService.getOrgId()}/campaigns`).snapshotChanges();
  }

  getCampaigns(): Observable<Campaign[]> {
    return this.db.list<Campaign>(`/organizations/${this.orgIdService.getOrgId()}/campaigns`).valueChanges();
  }

  saveCampaign(campaignRo: Partial<Campaign>) {
    const campaign = JSON.parse(JSON.stringify(campaignRo));

    console.log('Saving', campaign);
    if ((campaign.id !== undefined) && (campaign.id.length > 0)) {
      // Update existing
      console.log('Updating', campaign.id);
      this.db.object(`/organizations/${this.orgIdService.getOrgId()}/campaigns/${campaign.id}`).update(campaign).then(
        (retVal) => {
          console.log('Update success', retVal);
          this.store.dispatch(this.actions.saveSuccess());
        }, (error: Error) => {
          console.log('Error pushing', error);
        }
      );
    } else {
      // Add new
      delete campaign.id;
      console.log('Adding new', campaign);
      this.db.list(`/organizations/${this.orgIdService.getOrgId()}/campaigns`).push(campaign).then(
        (retVal) => {
          campaign.id = retVal.key;
          this.store.dispatch(this.actions.saveSuccess());
        }, (error: Error) => {
          console.log('Error pushing new contact', error);
        }
      );
    }
  }

  async duplicateCampaign(campaign: Partial<Campaign>, dupePhoneNumbers: boolean) {
    console.log('Duplicating', campaign);

    const srcId = campaign.id;

    const clone:any = {
      ...campaign,
      name: `${campaign.name} Copy`,
      counts: {
        BadNumber: 0,
        Declined: 0,
        Gave: 0,
        OptOut: 0,
        Pending: 0,
        Started: 0,
        Success: 0,
        WrongNumber: 0
      },
      messageStats: {}
    };
    clone.id = null;

    const retVal = await this.db.list(`/organizations/${this.orgIdService.getOrgId()}/campaigns`).push(clone);
    campaign.id = retVal.key;
    this.store.dispatch(this.actions.saveSuccess());

    console.log('Campaign cloned to new id of', retVal.key);

    if (dupePhoneNumbers) {
      await this.firebaseFunctions.httpsCallable('duplicatePhoneNumbers')({
        org: this.orgIdService.getOrgId(),
        srcCampaign: srcId,
        destCampaign: retVal.key
      }).toPromise();

      console.log('Campaign phone numbers duplicated');
    }
  }

  async archiveCampaign(campaignId: string) {
    console.log('Archiving', campaignId);

    try {
      const result = await this.firebaseFunctions.httpsCallable('archiveCampaign', { timeout: 540000 })({
        org: this.orgIdService.getOrgId(),
        campaignId: campaignId
      }).toPromise();
      console.log('Archive complete', result);
      await this.router.navigate(['admin', this.orgIdService.getOrgId(), 'campaigns']);
    } catch (err) {
      console.error('Archive error', err);
      alert('Error archiving');
    }
  }
}

import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

import { AppStore } from '../store/app.store';
import { OrgIdService } from './org-id.service';
import { Message } from '../model';
import { MessageActions } from '../store/actions';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(
    private store: Store<AppStore>,
    private db: AngularFireDatabase,
    private messageActions: MessageActions,
    private orgIdService: OrgIdService
  ) { }

  addMessage(campaignId: string, contactId: string, phoneNumber: string, message: Message) {
    const startAt:Date = new Date();
    this.db.list(`/organizations/${this.orgIdService.getOrgId()}/messages/${campaignId}/${contactId}/${phoneNumber}/sms`).push(message).then(
      (retVal) => {
        const finishAt:Date = new Date();
        // console.log("Message add success", startAt, finishAt, (finishAt.getTime()-startAt.getTime()), retVal);
        //this.store.dispatch(this.actions.saveSuccess());
      }, (error: Error) => {
        console.log("Error pushing", error);
      }
    );
  }

  loadMessages(campaignId: string, contactId: string, phoneNumber: string) {
    return new Promise( (resolve) => {
      this.db.object(`/organizations/${this.orgIdService.getOrgId()}/messages/${campaignId}/${contactId}/${phoneNumber}/sms`)
        .snapshotChanges()
        .pipe(take(1))
        .subscribe(action => {
          let obj = action.payload.val();
          resolve(obj);
        });
    });
  }
}

import { Observable } from 'rxjs';
import { Action } from '@ngrx/store';

import { ActionWithPayload, MessageActions } from '../actions';

export function messagesReducer(state: any = {}, action: ActionWithPayload<any>):any {
  switch(action.type) {
    case MessageActions.LOAD_ALL_FOR_CONVERSATION_SUCCESS:
      const val = action.payload;

      const newState = JSON.parse(JSON.stringify(state));
      // console.log("Got messages reducer all", val, action);
      if (!newState[val.campaignId]) newState[val.campaignId] = {};
      if (!newState[val.campaignId][val.contactId]) newState[val.campaignId][val.contactId] = {};
      newState[val.campaignId][val.contactId] = { ...val.messages };
      return newState;
    case MessageActions.LOAD_FOR_CONVERSATION_SUCCESS:
      // console.log("Updating messages in store", action, state);
      if (action != null) {
        return { ...state, [action.payload.conversationId]: { ...action.payload.value } };
      }
      return state;
    default:
      return state;
  }
};

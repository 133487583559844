import { Observable } from 'rxjs';
import { Action } from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createSelector, createFeatureSelector } from '@ngrx/store';

import { ActionWithPayload, TagActions } from '../actions';
import { Tag, TagState } from '../../model';

// Entity adapter
export const tagsAdapter = createEntityAdapter<Tag>();
export const initialState: TagState = tagsAdapter.getInitialState();

export function tagsReducer(state: TagState = initialState, action: ActionWithPayload<any>) {
  switch(action.type) {
    case TagActions.LOAD_ALL_SUCCESS:
      return tagsAdapter.setAll(action.payload, state);
    case TagActions.ADDED:
      return tagsAdapter.addOne(action.payload, state);
    case TagActions.MODIFIED:
      return tagsAdapter.updateOne({id: action.payload.id, changes: action.payload.changes}, state);
    case TagActions.REMOVED:
      return tagsAdapter.removeOne(action.payload.id, state);
    case TagActions.SAVE_SUCCESS:
      // console.log('TAG SAVE SUCCESS', action.payload);
      return state;
    default:
      return state;
  }
}

export function tagDictionary(state:any = {}, action: ActionWithPayload<Tag[]>):{[key: string]: Tag} {
  switch(action.type) {
    case TagActions.LOAD_ALL_SUCCESS:
      const data: Tag[] = action.payload;
      const dataDict: {[key: string]: Tag} = {};
      data.forEach(obj => {
        dataDict[obj.id] = obj;
      });
      return dataDict;
    default:
      return state;
  }
};

export const selectTagsState = createFeatureSelector<TagState>('tags');

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(
    private router: Router,
    private auth: AuthenticationService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
   return this.auth.authInfo
     .pipe(
       take(1),
       map(authInfo => !!authInfo),
       tap(auth => {
         if (!auth) return this.router.navigate(['/login']);

         if (this.auth.isPasswordChangeRequired()) {
           console.log("Must change password");
           this.auth.redirectUrl = state.url;
           this.router.navigate(['/changepassword']);
         } else if (route.data && route.data.expectedRole) {
           return this.auth.getStatus().pipe(
             filter(v => v === 'ready')
           ).subscribe(v => {
             return this.auth.isRoleAllowed(route.data.expectedRole) ? true : this.router.navigate(['/login']);
           });
         } else {
           return true;
         }
       })
     );
  }

}

<section class="slice slice-navbar bg-gradient-primary" data-separator-bg="secondary"><div></div></section>

<section class="slice slice-lg">
  <div class="container pt-lg-md">
      <div class="row justify-content-center">
          <div class="col-lg-6">
              <div class="card shadow-lg">
                  <div class="card-body px-lg-5 py-lg-5" *ngIf="!forgotPassword">
                      <div class="text-center mb-4">
                          <h2 class="parlance h2 pt-3">parlance</h2>
                          <h4 class="heading h4 pt-2">Log-in</h4>
                      </div>
                      <span class="clearfix"></span>
                      <div *ngIf="errorMessage" class="alert alert-danger">
                        {{errorMessage}}
                      </div>
                      <form role="form" [formGroup]="form">
                          <div class="form-group mb-4">
                              <input type="text" class="form-control form-control-lg form-control-emphasized" placeholder="Email" formControlName="email">
                          </div>
                          <div class="input-group mb-4">
                            <input [type]="showPassword ? 'text' : 'password'" class="form-control form-control-lg form-control-emphasized" placeholder="Password" formControlName="password">
                            <span class="input-group-text">
                              <i class="fa" [ngClass]="{ 'fa-eye-slash': !showPassword, 'fa-eye': showPassword }" (click)="toggleShowPassword()"></i>
                            </span>
                          </div>

                          <button class="btn btn-primary btn-block" (click)="loginViaEmailPassword()" [disabled]="!form.valid || processing">
                            <span *ngIf="!processing">Sign In</span>
                            <span *ngIf="processing"><i class="fas fa-spinner fa-spin"></i> Please wait</span>
                          </button>

                          <div class="text-center mt-3">
                            <button class="btn btn-link" (click)="showForgotPassword(true)">Forgot Password?</button>
                          </div>
                      </form>

                      <p class="text-muted text-center">(Looking to <a target="_blank" href="https://calendly.com/parlanceapp/parlance-introductory-call">sign-up your organization</a>?)</p>
                  </div>

                  <div class="card-body px-lg-5 py-lg-5" *ngIf="forgotPassword">
                      <div class="text-center mb-4">
                        <h2 class="parlance h2 pt-3">parlance</h2>
                        <h4 class="heading h4 pt-2">Password Reset</h4>
                        <p class="lead" *ngIf="!passwordReset">Provide your email address and we'll send you a link to reset your password</p>
                        <p class="lead" *ngIf="passwordReset">Password reset requested. Please check your email for instructions.</p>
                      </div>
                      <span class="clearfix"></span>
                      <form role="form" [formGroup]="resetForm" *ngIf="!passwordReset">
                        <div class="form-group mb-4">
                            <input type="text" class="form-control form-control-lg form-control-emphasized" placeholder="Email" formControlName="email">
                        </div>

                        <button class="btn btn-primary btn-block" (click)="resetPassword()" [disabled]="!resetForm.valid || processing">
                          <span *ngIf="!processing">Reset Password</span>
                          <span *ngIf="processing"><i class="fas fa-spinner fa-spin"></i> Please wait</span>
                        </button>
                      </form>

                      <div class="text-center mt-3">
                        <button class="btn btn-link" (click)="showForgotPassword(false)">Back to sign in</button>
                      </div>
                  </div>

              </div>
          </div>
      </div>
  </div>
</section>

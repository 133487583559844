import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { ActionWithPayload } from './action-with-payload';

import { Tag } from '../../model';

@Injectable()
export class TagActions {
  static LOAD_ALL = '[Tag] Load all tags';
  loadAll(): Action {
    return {
      type: TagActions.LOAD_ALL
    }
  }

  static LOAD_ALL_SUCCESS = '[Tag] value';
  loadAllSuccess(value): ActionWithPayload<any> {
    return {
      type: TagActions.LOAD_ALL_SUCCESS,
      payload: value
    }
  }

  static SAVE = '[Tag] Save tag';
  save(tag: Tag): ActionWithPayload<Tag> {
    return {
      type: TagActions.SAVE,
      payload: tag
    }
  }

  static DELETE = '[Tag] Delete tag';
  delete(tag: Tag): ActionWithPayload<Tag> {
    return {
      type: TagActions.DELETE,
      payload: tag
    }
  }

  static ADDED = '[Tag] added';
  added(tag: Tag): ActionWithPayload<Tag> {
    return {
      type: TagActions.ADDED,
      payload: tag
    }
  }

  static MODIFIED = '[Tag] modified';
  modified(tag: Tag): ActionWithPayload<Tag> {
    return {
      type: TagActions.MODIFIED,
      payload: tag
    }
  }

  static REMOVED = '[Tag] removed';
  removed(tag: Tag): ActionWithPayload<Tag> {
    return {
      type: TagActions.REMOVED,
      payload: tag
    }
  }

  static UPDATE = '[Tag] update';
  update(id: string, changes: Partial<Tag>): ActionWithPayload<{id:string, changes:Partial<Tag>}> {
    return {
      type: TagActions.UPDATE,
      payload: { id: id, changes: changes}
    }
  }

  static SAVE_SUCCESS = '[Tag] Save Success';
  saveSuccess(id: string, tag: Tag):  ActionWithPayload<{id:string, tag:Tag}> {
    return {
      type: TagActions.SAVE_SUCCESS,
      payload: { id: id, tag: tag }
    };
  }
}

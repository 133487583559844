import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Observable, of } from 'rxjs';
import { catchError, switchMap, exhaustMap, map, mergeMap, tap, filter, combineLatest } from 'rxjs/operators';

import { ActionWithPayload, UserActions } from '../actions';
import { UserService, OrgIdService } from '../../services';
import { User } from '../../model';

import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private userService: UserService,
    private userActions: UserActions,
    private db: AngularFireDatabase,
    private orgIdService: OrgIdService,
    private firebaseFunctions: AngularFireFunctions,
  ) {}


  loggedIn$ = createEffect(() => this.actions$.pipe(
    ofType<ActionWithPayload<string>>(UserActions.LOGGED_IN),
    switchMap(action => {
      // console.log('requesting logged in user info', action.payload);

      this.db.object(`/users/${action.payload}/lastSeenOn`).set(new Date().getTime());

      return this.db.object(`/users/${action.payload}`).snapshotChanges();
    }),
    catchError( (err, caught) => {
      console.warn('Error in UserEffects.loggedIn$', err, caught);
      return of(null);
    }),
    filter(v => !!v),
    map(action => {
      // console.log('logged in user change', action, action.payload.val());
      return {
        type: UserActions.UPDATE_LOGGED_IN_USER,
        payload: action.payload.val()
      };
    })
  ));


  // Need to load list of users for org from /organizations/${this.orgIdService.getOrgId()}/users
  // and all users from /users
  // Then filter for only those listed in /org/.../users

  loadAll$ = createEffect(() => this.actions$.pipe(
    ofType(UserActions.LOAD_ALL),
    switchMap(() => this.userService.loadAll()),
    catchError( (err, caught) => {
      console.warn('Error in UserEffects.loadAll$', err, caught);
      return of(null);
    }),
    filter(v => !!v),
    map(obj => {
      return {
        type: `[User] value`,
        payload: Object.keys(obj).map(key => ({id:key, ...obj[key]}))
      };
    })
  ));


  loadUsers$ = createEffect(() => this.actions$.pipe(
    ofType(UserActions.LOAD_LIST),
    switchMap(() => this.userService.getUsers()),
    map((users: User[]) => {
      return this.userActions.loadListSuccess(users);
    })
  ));


  inviteUser$ = createEffect(() => this.actions$.pipe(
    ofType<ActionWithPayload<{ user: User, skipInvite: boolean, initPassword: string}>>(UserActions.INVITE),
    tap((action) => this.userService.inviteUser(action.payload)),
    filter(() => false)
  ));


  saveUser$ = createEffect(() => this.actions$.pipe(
    ofType<ActionWithPayload<Partial<User>>>(UserActions.SAVE),
    tap((action) => this.userService.saveUser(action.payload)),
    filter(() => false)
  ));


  // @Effect()
  // updateImportMapping$ = this.action$.pipe(
  //   ofType<ActionWithPayload<{ mapping: any }>>(UserActions.UPDATE_CURRENT_USER_IMPORT_MAAPING),
  //   map(action => {
  //
  //   })
  // );


  deleteUsers$ = createEffect(() => this.actions$.pipe(
    ofType<ActionWithPayload<{deleteIds: string[]}>>(UserActions.DELETE_USERS),
    tap(async (action) => {
      const data = action.payload;
      console.log('Deleting users', data.deleteIds);

      try {
        const result = await this.firebaseFunctions.httpsCallable('deleteUsers', { timeout: 540000 })({org: this.orgIdService.getOrgId(), deleteUserIds: data.deleteIds}).toPromise();
        return true;
      } catch (err) {
        console.log('An error occurred deleting the selected users', err);
      }
      alert('An unknown error occurred deleting the selected users');
    }),
    filter(() => false)
  ));
}

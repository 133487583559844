import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Store } from '@ngrx/store';

import { User } from '../model';
import { UserActions } from '../store/actions';
import { AppStore } from '../store/app.store';
import { OrgIdService } from './org-id.service';

function encodeAsFirebaseKey(string) {
  return string.replace(/\%/g, '%25')
    .replace(/\./g, '%2E')
    .replace(/\#/g, '%23')
    .replace(/\$/g, '%24')
    .replace(/\//g, '%2F')
    .replace(/\[/g, '%5B')
    .replace(/\]/g, '%5D');
}

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private db: AngularFireDatabase,
    private firebaseFunctions: AngularFireFunctions,
    private userActions: UserActions,
    private store: Store<AppStore>,
    private orgIdService: OrgIdService,
    private router: Router) {
  }

  loadAll() {
    return Observable.create((observer:any) => {
      this.db.object('/users').snapshotChanges().subscribe(userData => {
        this.db.object(`/organizations/${this.orgIdService.getOrgId()}/users`).snapshotChanges().subscribe(allowedUsers => {
          const users = userData.payload.val();
          const authed = allowedUsers.payload.val();
          const result = {};
          Object.keys(users).forEach(uid => {
            if (!!authed[uid]) result[uid] = users[uid];
          });
          observer.next(result);
        }, (err) => {
          console.warn('Error in UserService.loadAll 1', err);
        });
      }, (err) => {
        console.warn('Error in UserService.loadAll 2', err);
      });
    });
  }

  getUsers(): Observable<User[]> {
    return this.db.list<User>('/users').valueChanges();
  }

  saveUser(user: Partial<User>) {
    console.log('Saving', user);
    if ((user.id !== undefined) && (user.id.length > 0)) {
      // Update existing user
      console.log('Updating', user.id);
      this.db.object(`/users/${user.id}`).update(user).then(
        (retVal) => {
          // console.log('Update success', retVal);
          this.store.dispatch(this.userActions.saveSuccess(user.id, user));
          return this.firebaseFunctions.httpsCallable('updateUser')({org: this.orgIdService.getOrgId(), user: user}).toPromise();
          // this.router.navigate(['/users']);
        }, (error: Error) => {
          console.log('Error pushing', error);
        }
      );
    } else {
      // Add new user
      console.log('Adding new');
      this.db.list('/users').push(user).then(
        (retVal) => {
          user.id = retVal.key;
          this.store.dispatch(this.userActions.saveSuccess(retVal.key, user));
          // this.router.navigate(['/users']);
        }, (error: Error) => {
          console.log('Error pushing new user', error);
        }
      );
    }
  }

  inviteUser(data: { user: User, skipInvite: boolean, initPassword: string}) {
    const user = JSON.parse(JSON.stringify(data.user));
    console.log('Inviting', user, data.skipInvite, data.initPassword);
    if (!user.id) user.id = '';
    user.forceChangePassword = (!data.initPassword);
    if (data.skipInvite) user.skipInvite = true;
    if (data.initPassword) user.password = data.initPassword;
    this.db.object(`/organizations/${this.orgIdService.getOrgId()}/user-invites/pending/${encodeAsFirebaseKey(user.contactInfo.email)}`).update(user).then(
      (retVal) => {
        // this.store.dispatch(this.userActions.saveSuccess(user));
      }, (error: Error) => {
        console.log('Error inviting user', error);
      }
    );
  }

  resendInvite(data: {userId: string, skipInvite: boolean, password: string}):Promise<any> {
    return this.firebaseFunctions.httpsCallable('resendInvite')({org: this.orgIdService.getOrgId(), userId: data.userId, password: data.password, skipInvite: data.skipInvite}).toPromise();
  }
}

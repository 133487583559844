import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { ActionWithPayload } from './action-with-payload';

import { User } from '../../model';

@Injectable()
export class UserActions {
  static LOGGED_IN = '[User] Logged In';
  loggedIn(userId: string): ActionWithPayload<string> {
    return {
      type: UserActions.LOGGED_IN,
      payload: userId
    };
  }

  static UPDATE_LOGGED_IN_USER = '[User] Update Logged In User';

  static LOGOFF = '[User] Log-off';
  logoff(): Action {
    return {
      type: UserActions.LOGOFF
    };
  }

  static LOGIN_SUCCESS = '[User] Log-in Success';
  loginSuccess(user: User): ActionWithPayload<User> {
    return {
      type: UserActions.LOGIN_SUCCESS,
      payload: user
    };
  }

  static LOAD_ALL = '[User] Load All';
  loadAll(): Action {
    return {
      type: UserActions.LOAD_ALL
    }
  }

  static LOAD_ALL_SUCCESS = '[User] value';
  loadAllSuccess(value): ActionWithPayload<any> {
    return {
      type: UserActions.LOAD_ALL_SUCCESS,
      payload: value
    }
  }

  static ADDED = '[User] added';
  added(user: User): ActionWithPayload<User> {
    return {
      type: UserActions.ADDED,
      payload: user
    }
  }

  static MODIFIED = '[User] modified';
  modified(user: User): ActionWithPayload<User> {
    return {
      type: UserActions.MODIFIED,
      payload: user
    }
  }

  static REMOVED = '[User] removed';
  removed(user: User): ActionWithPayload<User> {
    return {
      type: UserActions.REMOVED,
      payload: user
    }
  }

  static UPDATE = '[User] update';
  update(id: string, changes: Partial<User>): ActionWithPayload<{id:string, changes:Partial<User>}> {
    return {
      type: UserActions.UPDATE,
      payload: { id: id, changes: changes}
    }
  }

  static DELETE_USERS = '[User] Delete users';
  deleteUsers(data: {deleteIds: string[]}): ActionWithPayload<{deleteIds: string[]}> {
    return {
      type: UserActions.DELETE_USERS,
      payload: data,
    }
  }


  // OLD METHODS BELOW
  static LOAD_LIST = '[User] Load List';
  loadList(): Action {
    return {
      type: UserActions.LOAD_LIST
    };
  }

  static LOAD_LIST_SUCCESS = '[User] Load List Success';
  loadListSuccess(users: User[]): ActionWithPayload<User[]> {
    return {
      type: UserActions.LOAD_LIST_SUCCESS,
      payload: users
    };
  }

  static INVITE = '[User] Invite';
  invite(user: User, skipInvite: boolean = false, initPassword: string = null): ActionWithPayload<{ user: User, skipInvite: boolean, initPassword: string}> {
    return {
      type: UserActions.INVITE,
      payload: { user: user, skipInvite: skipInvite, initPassword: initPassword }
    };
  }

  static SAVE = '[User] Save';
  save(user: Partial<User>): ActionWithPayload<Partial<User>> {
    return {
      type: UserActions.SAVE,
      payload: user
    };
  }

  static SAVE_SUCCESS = '[User] Save Success';
  saveSuccess(id: string, user: Partial<User>): ActionWithPayload<{id: string, user: Partial<User>}> {
    return {
      type: UserActions.SAVE_SUCCESS,
      payload: {
        id: id,
        user: user
      }
    };
  }
}

import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { ActionWithPayload } from './action-with-payload';

import { Campaign } from '../../model';

@Injectable()
export class CampaignActions {
  static LOAD_ALL = '[Campaign] Load All';
  loadAll(): Action {
    return {
      type: CampaignActions.LOAD_ALL
    };
  }

  static LOAD_ALL_SUCCESS = '[Campaign] value';
  loadAllSuccess(value): ActionWithPayload<any> {
    return {
      type: CampaignActions.LOAD_ALL_SUCCESS,
      payload: value
    };
  }

  static LOAD_CAMPAIGNS_FOR_USER = '[Campaign] Load for user';
  loadForUser(data:{userId:string}): ActionWithPayload<{userId:string}> {
    return {
      type: CampaignActions.LOAD_CAMPAIGNS_FOR_USER,
      payload: data
    };
  }

  static LOAD_CAMPAIGNS_FOR_USER_SUCCESS = '[Campaign] Load for user success';
  loadForUserSuccess(data:{userId:string, campaigns:any[]}): ActionWithPayload<{userId:string, campaigns:any[]}> {
    return {
      type: CampaignActions.LOAD_CAMPAIGNS_FOR_USER_SUCCESS,
      payload: data
    };
  }

  static ADDED = '[Campaign] added';
  added(obj: Campaign): ActionWithPayload<Campaign> {
    return {
      type: CampaignActions.ADDED,
      payload: obj
    };
  }

  static MODIFIED = '[Campaign] modified';
  modified(obj: Campaign): ActionWithPayload<Campaign> {
    return {
      type: CampaignActions.MODIFIED,
      payload: obj
    };
  }

  static REMOVED = '[Campaign] removed';
  removed(obj: Campaign): ActionWithPayload<Campaign> {
    return {
      type: CampaignActions.REMOVED,
      payload: obj
    };
  }

  static UPDATE = '[Campaign] update';
  update(id: string, changes: Partial<Campaign>): ActionWithPayload<{id:string, changes:Partial<Campaign>}> {
    return {
      type: CampaignActions.UPDATE,
      payload: { id: id, changes: changes}
    };
  }

  static DUPLICATE = '[Campaign] Duplicate';
  duplicate(campaign: Partial<Campaign>, dupeNumbers: boolean): ActionWithPayload<{ campaign: Partial<Campaign>, duplicatePhoneNumbers: boolean }> {
    return {
      type: CampaignActions.DUPLICATE,
      payload: { campaign: campaign, duplicatePhoneNumbers: dupeNumbers }
    };
  }

  static ARCHIVE = '[Campaign] Archive';
  archive(campaignId: string): ActionWithPayload<{ campaignId: string }> {
    return {
      type: CampaignActions.ARCHIVE,
      payload: { campaignId: campaignId }
    };
  }

  static SAVE = '[Campaign] Save';
  save(family: Partial<Campaign>): ActionWithPayload<Partial<Campaign>> {
    return {
      type: CampaignActions.SAVE,
      payload: family
    };
  }

  static SAVE_SUCCESS = '[Campaign] Save Success';
  saveSuccess(): Action {
    return {
      type: CampaignActions.SAVE_SUCCESS
    };
  }
}

import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { ActionWithPayload } from './action-with-payload';

import { Message } from '../../model';

@Injectable()
export class MessageActions {
  static CLEAR_REVIVED = '[Message] Clear revived';
  clearRevived(data:{campaignId:string, contactId:string}): ActionWithPayload<{campaignId:string, contactId:string}> {
    return {
      type: MessageActions.CLEAR_REVIVED,
      payload: data
    }
  }

  static SET_REVIVED = '[Message] Set revived';
  setRevived(data:{campaignId:string, contactId:string}): ActionWithPayload<{campaignId:string, contactId:string}> {
    return {
      type: MessageActions.SET_REVIVED,
      payload: data
    }
  }

  static UPDATE_LAST_READ = '[Message] Update message read';
  updateLastMessageRead(data:{campaignId:string, contactId:string}): ActionWithPayload<{campaignId:string, contactId:string}> {
    return {
      type: MessageActions.UPDATE_LAST_READ,
      payload: data
    }
  }

  static UPDATE_LAST_VIEWED = '[Message] Update last viewed';
  updateLastMessageViewed(data:{campaignId:string, contactId:string, phoneNumber:string, method:string, uid:string, lastKey:string}): ActionWithPayload<{campaignId:string, contactId:string, phoneNumber:string, method:string, uid:string, lastKey:string}> {
    return {
      type: MessageActions.UPDATE_LAST_VIEWED,
      payload: data
    }
  }

  static LOAD_ALL_FOR_CONVERSATION = '[Message] Load all for Conversation';
  loadAllForConversation(data:{campaignId: string, contactId: string}): ActionWithPayload<{campaignId:string, contactId:string}> {
    return {
      type: MessageActions.LOAD_ALL_FOR_CONVERSATION,
      payload: data
    }
  }

  static LOAD_ALL_FOR_CONVERSATION_SUCCESS = '[Message] Load all for Conversation Success';
  loadAllForConversationSuccess(value): ActionWithPayload<any> {
    return {
      type: MessageActions.LOAD_ALL_FOR_CONVERSATION_SUCCESS,
      payload: value
    }
  }

  static LOAD_FOR_CONVERSATION = '[Message] Load for Conversation';
  loadForConversation(data:{campaignId: string, contactId: string, phoneNumber: string}): ActionWithPayload<{campaignId:string, contactId:string, phoneNumber:string}> {
    return {
      type: MessageActions.LOAD_FOR_CONVERSATION,
      payload: data
    }
  }

  static LOAD_FOR_CONVERSATION_SUCCESS = '[Message] Load for Conversation Success';
  loadForConversationSuccess(value): ActionWithPayload<any> {
    return {
      type: MessageActions.LOAD_FOR_CONVERSATION_SUCCESS,
      payload: value
    }
  }

  static ADD = '[Message] Add message';
  add(data:{campaignId: string, contactId:string, phoneNumber: string, message: Message}): ActionWithPayload<{campaignId:string, contactId:string, phoneNumber:string, message: Message}> {
    return {
      type: MessageActions.ADD,
      payload: data
    }
  }
}

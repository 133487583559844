import { Observable } from 'rxjs';
import { Action } from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createSelector, createFeatureSelector } from '@ngrx/store';

import { ActionWithPayload, CampaignActions } from '../actions';
import { Campaign, CampaignState } from '../../model';

// Entity adapter
export const campaignAdapter = createEntityAdapter<Campaign>();
export const initialState: CampaignState = campaignAdapter.getInitialState();

export function userToCampaignsReducer(state: any = {}, action: ActionWithPayload<any>) {
  switch(action.type) {
    case CampaignActions.LOAD_CAMPAIGNS_FOR_USER_SUCCESS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export function campaignsReducer(state: CampaignState = initialState, action: ActionWithPayload<any>) {
  switch(action.type) {
    case CampaignActions.LOAD_ALL_SUCCESS:
      return campaignAdapter.setAll(action.payload, state);
    case CampaignActions.ADDED:
      return campaignAdapter.addOne(action.payload, state);
    case CampaignActions.MODIFIED:
      return campaignAdapter.updateOne({id: action.payload.id, changes: action.payload.changes}, state);
    case CampaignActions.REMOVED:
      return campaignAdapter.removeOne(action.payload.id, state);
    default:
      return state;
  }
}

export const selectCampaignState = createFeatureSelector<CampaignState>('campaigns');

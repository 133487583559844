import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { ActionWithPayload } from './action-with-payload';

import { Family } from '../../model';

@Injectable()
export class ContactActions {
  static LOAD_ALL = '[Contact] Load All';
  loadAll(): Action {
    return {
      type: ContactActions.LOAD_ALL
    }
  }

  static LOAD_ALL_SUCCESS = '[Contact] value';
  loadAllSuccess(value): ActionWithPayload<any> {
    return {
      type: ContactActions.LOAD_ALL_SUCCESS,
      payload: value
    }
  }

  static ADDED = '[Contact] added';
  added(family: Family): ActionWithPayload<Family> {
    return {
      type: ContactActions.ADDED,
      payload: family
    }
  }

  static MODIFIED = '[Contact] modified';
  modified(family: Family): ActionWithPayload<Family> {
    return {
      type: ContactActions.MODIFIED,
      payload: family
    }
  }

  static REMOVED = '[Contact] removed';
  removed(family: Family): ActionWithPayload<Family> {
    return {
      type: ContactActions.REMOVED,
      payload: family
    }
  }

  static UPDATE = '[Contact] update';
  update(id: string, changes: Partial<Family>): ActionWithPayload<{id:string, changes:Partial<Family>}> {
    return {
      type: ContactActions.UPDATE,
      payload: { id: id, changes: changes}
    }
  }

  static SAVE = '[Contact] Save';
  save(data: {family: Family, redirectOnSuccess?: boolean}): ActionWithPayload<{family: Family, redirectOnSuccess?: boolean}> {
    return {
      type: ContactActions.SAVE,
      payload: data
    };
  }

  static SAVE_THEN_PERFORM_ACTIONS = '[Contact] Save Then Perform Actions';
  saveContactThenPerformActions(data: {contact:Family, actions:Action[]}): ActionWithPayload<{contact:Family, actions:Action[]}> {
    return {
      type: ContactActions.SAVE_THEN_PERFORM_ACTIONS,
      payload: data
    };
  }

  static SAVE_SUCCESS = '[Contact] Save Success';
  saveSuccess(): Action {
    return {
      type: ContactActions.SAVE_SUCCESS
    };
  }

  static DELETE_CONTACTS = '[Contact] Delete contacts';
  deleteContacts(data: {contactIds:any}): ActionWithPayload<{contactIds:any}> {
    return {
      type: ContactActions.DELETE_CONTACTS,
      payload: data,
    }
  }

}

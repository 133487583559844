import { EntityState } from '@ngrx/entity';
import * as _ from 'lodash';

import { ContactInfo } from './contact-info';

export class CustomFieldValue {
  fieldName: string = '';
  value: string = '';

  constructor(json?:any) {
    if (json) {
      this.fieldName = _.get(json, 'fieldName', '');
      this.value = _.get(json, 'value', '');
    }
  }
}

export class Family {
  id?: string;
  externalId?: string;
  contacts: ContactInfo[];
  notes?: string;
  extraField?: string;
  userDefined?: CustomFieldValue[];
  tags?: string[];
  doNotText?: boolean;
  doNotCall?: boolean;
  updatedOn?: number;
  importedOn?: number;
  history?: Family[];

  constructor(json?: any) {
    this.contacts = [];

    if (json) {
      if (json.id && json.id != `-1`) {
        this.id = json.id;
      }
      this.externalId = json.externalId || null;
      if (json.contacts) {
        for(let i=0; i<json.contacts.length; i++) {
          this.contacts.push(new ContactInfo(json.contacts[i]));
        }
      }
      this.notes = (json?.notes !== null && json?.notes !== undefined) ? json.notes : null;
      this.extraField = (json?.extraField !== null && json?.extraField !== undefined) ? json.extraField : null;
      this.userDefined = json.userDefined || [];
      this.tags = json.tags || [];
      this.doNotCall = json.doNotCall || false;
      this.doNotText = json.doNotText || false;
      this.updatedOn = json.updatedOn || 0;
      this.importedOn = json.importedOn || 0;
      this.history = json.history || [];
    } else {
      this.id = undefined;
      this.externalId = null;
      this.contacts.push(new ContactInfo());
      this.notes = null;
      this.extraField = null;
      this.userDefined = [];
      this.tags = [];
      this.doNotCall = false;
      this.doNotText = false;
      this.updatedOn = 0;
      this.importedOn = 0;
      this.history = [];
    }
  }

  getCustomFieldValue(fieldName:string) {
    if (this.userDefined) {
      // console.log('user defined', this.userDefined);
      const val = this.userDefined.find(cfv => cfv.fieldName === fieldName);
      if (val !== undefined && val.value !== undefined) return val.value;
    }
    return '';
  }

  validate(): string {
    if (this.contacts.length === 0) return 'Please set a primary contact';

    let err = this.contacts[0].validate('primary', true);
    if (err) return err;

    if (this.contacts.length > 1) {
      for(let i=1; i<this.contacts.length; i++) {
        err = this.contacts[i].validate('secondary', false);
        if (err) return err;
      }
    }

    return undefined;
  }
}

export class Contact {
  relationship?: string;
  externalId?: string;
  contactInfo: ContactInfo;

  constructor(json?: any) {
    if (json) {
      this.contactInfo = new ContactInfo(json.contactInfo);
      this.externalId = json.externalId || null;
      this.relationship = json.relationship || null;
    } else {
      this.contactInfo = new ContactInfo();
      this.externalId = null;
      this.relationship = null;
    }
  }

  validate(contactPosition: string, required: boolean): string {
    if (!this.contactInfo) return `Please set the ${contactPosition} contact information`;
    return this.contactInfo.validate(contactPosition, required);
  }
}

export interface ContactState extends EntityState<Family> { }

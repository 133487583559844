import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { ActionWithPayload } from './action-with-payload';

import { Settings } from '../../model';

@Injectable()
export class SettingsActions {
  static LOAD = '[Settings] Load settings';
  load(): Action {
    return {
      type: SettingsActions.LOAD
    }
  }

  static LOAD_SUCCESS = '[Settings] value';
  loadSuccess(value): ActionWithPayload<any> {
    return {
      type: SettingsActions.LOAD_SUCCESS,
      payload: value
    }
  }

  static SAVE = '[Settings] Save settings';
  save(settings: Settings): ActionWithPayload<Settings> {
    return {
      type: SettingsActions.SAVE,
      payload: settings
    }
  }

  static ADDED = '[Settings] added';
  added(settings: Settings): ActionWithPayload<Settings> {
    return {
      type: SettingsActions.ADDED,
      payload: settings
    }
  }

  static MODIFIED = '[Settings] modified';
  modified(settings: Settings): ActionWithPayload<Settings> {
    return {
      type: SettingsActions.MODIFIED,
      payload: settings
    }
  }

  static REMOVED = '[Settings] removed';
  removed(settings: Settings): ActionWithPayload<Settings> {
    return {
      type: SettingsActions.REMOVED,
      payload: settings
    }
  }

  static UPDATE = '[Settings] update';
  update(id: string, changes: Partial<Settings>): ActionWithPayload<Partial<Settings>> {
    return {
      type: SettingsActions.UPDATE,
      payload: changes
    }
  }
}

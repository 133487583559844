export enum Textable {
  Unknown = 'UNKNOWN',
  Yes = 'YES',
  No = 'NO',
  OptOut = 'OPT-OUT',
  Invalid = 'INVALID'
}

const EMAIL_REGEXP =
    /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;

export class ContactInfo {
  title: string;
  firstName: string;
  lastName: string;
  nickname: string;
  email: string;
  phoneNumber: string;
  phoneInvalid: boolean;
  altNumber: string;
  altInvalid: boolean;
  textable: Textable;
  userDefined?: object;
  street: string;
  street2: string;
  city: string;
  state: string;
  zip: string;
  personId: string;

  constructor(json?: any) {
    if (json) {
      this.title = json.title || '';
      this.firstName = json.firstName || '';
      this.lastName = json.lastName || '';
      this.email = json.email || '';
      this.phoneNumber = json.phoneNumber || '';
      this.phoneInvalid = json.phoneInvalid || false;
      this.altNumber = json.altNumber || '';
      this.altInvalid = json.altInvalid || false;
      this.textable = json.textable || Textable.Unknown;
      this.nickname = json.nickname || '';
      this.street = json.street || '';
      this.street2 = json.street2 || '';
      this.city = json.city || '';
      this.state = json.state || '';
      this.zip = json.zip || '';
      this.personId = json.personId || '';
    } else {
      this.title = '';
      this.firstName = '';
      this.lastName = '';
      this.email = '';
      this.phoneNumber = '';
      this.phoneInvalid = false;
      this.altNumber = '';
      this.altInvalid = false;
      this.textable = Textable.Unknown;
      this.nickname = '';
      this.street = '';
      this.street2 = '';
      this.city = '';
      this.state = '';
      this.zip = '';
      this.personId =  '';
    }
  }

  validate(contactPosition: string, required: boolean): string {
    if (required && (!this.firstName || this.firstName.length <= 0)) return `No first name for ${contactPosition} contact`;
    // if (required && (!this.lastName || this.lastName.length <= 0)) return `No last name for ${contactPosition} contact`;
    if (this.email && this.email.length > 0 && !this.validateEmail(this.email)) return `Invalid email for ${contactPosition} contact`;
    if (this.phoneNumber && this.phoneNumber.length > 0 && !this.validatePhone(this.phoneNumber)) return `Invalid phone for ${contactPosition} contact`;
    return undefined;
  }

  removeBadEmail() {
    if (this.email && this.email.length > 0 && !this.validateEmail(this.email)) {
      this.email = '';
    }
  }

  validateEmail(e: string): boolean {
    if (e === '|*REMOVE*|') return true;
    return EMAIL_REGEXP.test(e) ? true : false;
  }

  validatePhone(p: string): boolean {
    if (p === '|*REMOVE*|') return true;
    return p.match(/[^$,.\d]/) ? true : false;
  }
}

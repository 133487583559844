import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { Observable, of } from 'rxjs';
import { catchError, switchMap, exhaustMap, map, mergeMap, tap, filter } from 'rxjs/operators';

import { ActionWithPayload, TagActions } from '../actions';
import { OrgIdService } from '../../services';
import { Tag } from '../../model';

import { AngularFireDatabase } from '@angular/fire/compat/database';

@Injectable()
export class TagEffects {
  constructor(
    private actions$: Actions,
    private actions: TagActions,
    private db: AngularFireDatabase,
    private orgIdService: OrgIdService
  ) {}

  
  loadAll$ = createEffect(() => this.actions$.pipe(
    ofType(TagActions.LOAD_ALL),
    switchMap(action => {
      return this.db.object(`/organizations/${this.orgIdService.getOrgId()}/tags`).snapshotChanges();
    }),
    catchError( (err, caught) => {
      console.warn('Error in TagEffects.loadAll$', err, caught);
      return of(null);
    }),
    filter(v => !!v),
    map(action => {
      const obj = action.payload.val() || {};
      return {
        type: `[Tag] ${action.type}`,
        payload: Object.keys(obj).map(key => ({id:key, ...obj[key]}))
      };
    })
  ));

  
  saveTag$ = createEffect(() => this.actions$.pipe(
    ofType<ActionWithPayload<Tag>>(TagActions.SAVE),
    mergeMap(async (action) => {
      const tag = action.payload;
      console.log('Saving', tag);
      if ((tag.id !== undefined) && (tag.id.length > 0)) {
        // Update existing
        console.log('Updating', tag.id);
        const retVal = await this.db.object(`/organizations/${this.orgIdService.getOrgId()}/tags/${tag.id}`).update(tag);
        // console.log('Update success', retVal);
        return null;
      } else {
        // Add new
        console.log('Adding new');
        const retVal = await this.db.list(`/organizations/${this.orgIdService.getOrgId()}/tags`).push(tag);
        // tag.id = retVal.key;
        return this.actions.saveSuccess(retVal.key, tag);
      }
    }),
    filter((action) => !!action)
  ));

  
  deleteTag$ = createEffect(() => this.actions$.pipe(
    ofType<ActionWithPayload<Tag>>(TagActions.DELETE),
    tap((action) => {
      const tag = action.payload;
      console.log('Deleting', tag);
      if ((tag.id !== undefined) && (tag.id.length > 0)) {
        // Delete existing
        console.log('Deleting', tag.id);
        this.db.object(`/organizations/${this.orgIdService.getOrgId()}/tags/${tag.id}`).remove().then(
          (retVal) => {
            // console.log('Delete success', retVal);
          }, (error: Error) => {
            console.log('Error deleting', error);
          }
        );
      }
    })
  ), { dispatch: false });
}
